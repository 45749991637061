import { SortableFields, SortOrder } from "~/graphql/propeller/generated";
import type { FilterGroupMultiCheck, FilterGroupRange, PriceFilter } from "~/models/filters";
import type { Pagination } from "~/models/pagination";
import type { ProductListItem, ProductListPrice } from "~/models/products";
import type { SearchProductsRequest } from "~/server/api/ecom/products/search.get";
import type { CustomCart } from "~/server/utils/mapping/cartMapping";

export function useCart(): Ref<CustomCart> {
  return useState(
    "cart",
    () => ref({
      items: null,
      products: [] as ProductListItem[],
    } as CustomCart),
  );
}

export function useCatalogTextFilters(): Ref<Array<FilterGroupMultiCheck>> {
  return useState<Array<FilterGroupMultiCheck>>(
    "catalog-text-filters",
    () => reactive<Array<FilterGroupMultiCheck>>([]),
  );
}

export function useCatalogRangeFilters(): Ref<Array<FilterGroupRange>> {
  return useState<Array<FilterGroupRange>>(
    "catalog-range-filters",
    () => reactive<Array<FilterGroupRange>>([]),
  );
}

export function useCatalogPriceFilter(): Ref<PriceFilter> {
  return useState<PriceFilter>(
    "catalog-price-filter",
    () => reactive<PriceFilter>({
      from: 0,
      to: 0,
    }),
  );
}

export function useCatalogPagination(): Ref<Pagination> {
  return useState<Pagination>(
    "catalog-pagination",
    () => ref<Pagination>({
      pageSize: 0,
      totalItems: 0,
    }),
  );
}

export function useCatalogProducts(): Ref<ProductListItem[]> {
  return useState<ProductListItem[]>(
    "catalog-products",
    () => reactive<ProductListItem[]>([]),
  );
}

export function useCatalogPrices(): Ref<ProductListPrice> {
  return useState<ProductListPrice>(
    "catalog-prices",
    () => reactive<ProductListPrice>({
      min: 0,
      max: 0,
    }),
  );
}

export function useCatalogSearchQuery(): Ref<SearchProductsRequest> {
  return useState<SearchProductsRequest>(
    "catalog-search-query",
    () => reactive<SearchProductsRequest>({
      searchType: "catalog",
      locale: "", // is set later
      pageSize: 24,
      page: 1,
      sortField: SortableFields.name,
      sortOrder: SortOrder.ASC,
    }),
  );
}

export function useCheckoutAvailableDates(): Ref<Date[]> {
  return useState<Date[]>(
    "checkout-available-dates",
    () => ref([]),
  );
}

export function useCheckoutInformation() {
  return useState(
    "checkout-information",
    () => ref({
      isAuthenticated: false,
      invoiceAddressCode: "",
    }),
  );
}

export function useCheckoutLogistics() {
  return useState(
    "checkout-logistics",
    () => ref({
      isAuthenticated: false,
      isSameAsInvoiceAddress: false,
      invoiceAddressCode: "",
      deliveryAddressCode: "",
    }),
  );
}

export function useCheckoutReview() {
  return useState(
    "checkout-review",
    () => ref({
      isAccepted: false,
    }),
  );
}

export function useMiniCart() {
  return useState(
    "mini-cart",
    () => ref({
      isOpen: false,
      notification: "",
    }),
  );
}

export function useMobileMenu() {
  return useState(
    "mobile-menu",
    () => ref({
      isOpen: false,
    }),
  );
}

export function useMobileSearch() {
  return useState(
    "mobile-search",
    () => ref({
      isOpen: false,
    }),
  );
}

export function useReferrer(): Ref<string | undefined> {
  return useState<string | undefined>(
    "referrer",
    () => ref(undefined),
  );
}

export function useSearchTerm(): Ref<string> {
  return useState<string>(
    "search-term",
    () => ref(""),
  );
}
