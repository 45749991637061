import type { WebhookPayload } from "@esc-bv/ebs-nuxt-datocms-preview/server/api/datocms/preview-links";

export default defineAppConfig({
  /**
   * Used in page titles.
   */
  projectName: "Wijnen De Clerck",

  /**
   * Color for manifest that goes along with the favicons.
   */
  themeColorLightMode: "#cc9f26",

  /**
   * Color for manifest that goes along with the favicons.
   */
  themeColorDarkMode: "#cc9f26",

  datoCmsPreview: {
    webhookSecretValue: "af1237fdfff0472287921e6721b11ab2",
    cryptoPassword: "758eb590c80341c5a4898555a896566f",
    generatePreviewUrl: generatePreviewUrl,
  },

  /**
   * NuxtUI customizations.
   */
  ui: {
    primary: "brand.primary",
    gray: "brand.neutral",
    button: {
      strategy: "override",
      base: "flex flex-wrap justify-center transition disabled:cursor-not-allowed disabled:opacity-60",
      color: {
        black: {
          solid: "bg-gray-900 border-gray-900 hover:bg-gray-700",
          outline: "bg-white border-gray-900 hover:bg-gray-200",
        },
      },
      rounded: "rounded-lg",
      size: {
        md: "px-5 py-2.5",
      },
      variant: {
        solid: "border-{color}-500 bg-{color}-500 hover:bg-{color}-600 text-white",
        outline: "border-{color}-500 bg-white hover:bg-{color}-50 text-{color}-500",
        soft: "bg-{color}-100 hover:bg-{color}-200 text-{color}-500",
      },
      default: { loadingIcon: "i-svg-spinners-ring-resize" },
    },
    notifications: {
      position: "top-0 bottom-auto",
    },
    pagination: {
      strategy: "override",
      base: "border-0 ring-0 shadow-none size-10 flex justify-center",
      rounded: "rounded-md first-of-type:rounded-md last-of-type:rounded-md",
    },
    table: {
      divide: "divide-gray-300",
    },
  },
});

function generatePreviewUrl({ item, itemType, locale }: WebhookPayload) {
  const localePrefix = locale.toLowerCase();
  const siteLocale = getDatoLocaleFromCulture(localePrefix);
  const slug = (item.attributes.slug && item.attributes.slug[locale]) || "";
  switch (itemType.attributes.api_key) {
    case "domain":
      return `/${localePrefix}${getSlugPrefix("domains", siteLocale)}/${slug}`;
    case "gallery":
      return `/${localePrefix}${getSlugPrefix("galleries", siteLocale)}/${slug}`;
    case "page":
      return `/${localePrefix}/${slug}`;
    case "error404":
      return `/${localePrefix}/datocms-preview-404-page`;
    case "layout":
      return `/${localePrefix}/`;
    default:
      return null;
  }
}
