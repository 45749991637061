<script setup lang="ts">
import { baseUrl } from "~/config/canonicalSettings";

const organization = await useOrganization();

// fallback
useHead({
  title: organization.value?.name ?? "Wijnen De Clerck",
});

// structured data
useSchemaOrg([
  defineOrganization({
    name: organization.value?.name ?? "Wijnen De Clerck",
    address: {
      addressCountry: organization.value?.countryCode,
      addressLocality: organization.value?.city,
      postalCode: organization.value?.postalCode,
      streetAddress: `${organization.value?.street} ${organization.value?.number}`,
    },
    email: organization.value?.email,
    telephone: organization.value?.phone,
    logo: `${baseUrl}/logo/wijnen-de-clerck.png`,
  }),
]);
</script>

<template>
  <NuxtLoadingIndicator color="#cc9f26" />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UModals />

  <UNotifications>
    <template #description="{ description }">
      <span v-html="description" />
    </template>
  </UNotifications>
</template>
