<script lang="ts" setup>
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { ImageFileField, SplitItemRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: SplitItemRecord }>();

const localePath = useLocalePath();
const linkItem = computed(() => getLinkForType(props.data.optLinkItem, localePath));
</script>

<template>
  <div class="grid grid-cols-1 lg:grid-cols-2">
    <div
      :class="{ 'order-1 lg:order-1': data.imagePosition, 'order-1 lg:order-2': !data.imagePosition }"
      class="relative"
    >
      <DatocmsImage
        v-if="data.image as ImageFileField"
        :data="data.image?.responsiveImage as ResponsiveImageType"
        object-fit="cover"
      />

      <UButton
        v-if="linkItem.href"
        variant="solid"
        color="white"
        size="xl"
        :to="linkItem.href"
        :target="linkItem.target ?? '_self'"
        class="absolute bottom-7"
        :class="{ 'right-7': data.imagePosition, 'left-7': !data.imagePosition }"
      >
        {{ linkItem.label }}
      </UButton>
    </div>

    <div
      :class="{ 'order-2 lg:order-1': !data.imagePosition, 'order-2 lg:order-2': data.imagePosition }"
      class="flex items-center px-0 py-5 text-sm lg:px-10 lg:py-0 xl:py-10"
    >
      <div class="html-content">
        <h2 v-if="data.optTitle">
          {{ data.optTitle }}
        </h2>

        <div v-html="data.text" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
