import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { GraphQLError, print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BooleanType: { input: boolean; output: boolean; }
  CustomData: { input: Record<string, unknown>; output: Record<string, unknown>; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  FloatType: { input: number; output: number; }
  IntType: { input: number; output: number; }
  ItemId: { input: string; output: string; }
  JsonField: { input: unknown; output: unknown; }
  MetaTagAttributes: { input: Record<string, string>; output: Record<string, string>; }
  UploadId: { input: string; output: string; }
};

/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord = RecordInterface & {
  __typename: 'AccountPageRecord';
  _allLoginIntroHtmlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allRegistrationButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allRegistrationIntroHtmlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allRegistrationTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetRequestButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetRequestConfirmationHtmlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetRequestIntroHtmlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetRequestTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  loginIntroHtml: Scalars['String']['output'];
  loginTitle: Scalars['String']['output'];
  registrationButtonText: Scalars['String']['output'];
  registrationIntroHtml: Scalars['String']['output'];
  registrationTitle: Scalars['String']['output'];
  resetRequestButtonText: Scalars['String']['output'];
  resetRequestConfirmationHtml: Scalars['String']['output'];
  resetRequestIntroHtml: Scalars['String']['output'];
  resetRequestTitle: Scalars['String']['output'];
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginIntroHtmlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllRegistrationButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllRegistrationIntroHtmlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllRegistrationTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetRequestButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetRequestConfirmationHtmlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetRequestIntroHtmlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetRequestTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginIntroHtmlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordRegistrationButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordRegistrationIntroHtmlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordRegistrationTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetRequestButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetRequestConfirmationHtmlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetRequestIntroHtmlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetRequestTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord = RecordInterface & {
  __typename: 'AddressFormRecord';
  _allConfirmationLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allExceptionLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  confirmation: NotificationRecord;
  countries: Array<CountryRecord>;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord_AllConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord_AllExceptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecordExceptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ✉️ Address Form (address_form) */
export type AddressFormRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🏰 All Domains Section (all_domains_section) */
export type AllDomainsSectionRecord = RecordInterface & {
  __typename: 'AllDomainsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  domains: Array<DomainRecord>;
  id: Scalars['ItemId']['output'];
};


/** Block of type 🏰 All Domains Section (all_domains_section) */
export type AllDomainsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🖼️ All Galleries section (all_galleries_section) */
export type AllGalleriesSectionRecord = RecordInterface & {
  __typename: 'AllGalleriesSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


/** Block of type 🖼️ All Galleries section (all_galleries_section) */
export type AllGalleriesSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ArticleTeaserModelLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 📝 Article Teaser (article_teaser) */
export type ArticleTeaserRecord = RecordInterface & {
  __typename: 'ArticleTeaserRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  linkItem: ArticleTeaserModelLinkItemField;
  title: Scalars['String']['output'];
};


/** Block of type 📝 Article Teaser (article_teaser) */
export type ArticleTeaserRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 📝 Articles section (articles_section) */
export type ArticlesSectionRecord = RecordInterface & {
  __typename: 'ArticlesSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  teasers: Array<ArticleTeaserRecord>;
};


/** Block of type 📝 Articles section (articles_section) */
export type ArticlesSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🚩 Banner Item (banner_item) */
export type BannerItemRecord = RecordInterface & {
  __typename: 'BannerItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
};


/** Block of type 🚩 Banner Item (banner_item) */
export type BannerItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🚩 Banner section (banner_section) */
export type BannerSectionRecord = RecordInterface & {
  __typename: 'BannerSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  optText?: Maybe<Scalars['String']['output']>;
  optTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🚩 Banner section (banner_section) */
export type BannerSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Block of type ✔️ Bullet List (bullet_list) */
export type BulletListRecord = RecordInterface & {
  __typename: 'BulletListRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  list: Scalars['String']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type ✔️ Bullet List (bullet_list) */
export type BulletListRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type ✔️ Bullet List (bullet_list) */
export type BulletListRecordListArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulletListRecordMultiLocaleField = {
  __typename: 'BulletListRecordMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<BulletListRecord>;
};

/** Block of type 📦 Categories section (categories_section) */
export type CategoriesSectionRecord = RecordInterface & {
  __typename: 'CategoriesSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  categories?: Maybe<Scalars['JsonField']['output']>;
  id: Scalars['ItemId']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 📦 Categories section (categories_section) */
export type CategoriesSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CategoryAdviceModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CategoryAdviceModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CategoryAdviceModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  category?: InputMaybe<JsonFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
};

export type CategoryAdviceModelLinkField = CustomLinkRecord | PageLinkRecord;

export type CategoryAdviceModelLinkFieldNonNullMultiLocaleField = {
  __typename: 'CategoryAdviceModelLinkFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: CategoryAdviceModelLinkField;
};

export enum CategoryAdviceModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Record of type 💁 Category Advice (category_advice) */
export type CategoryAdviceRecord = RecordInterface & {
  __typename: 'CategoryAdviceRecord';
  _allLinkLocales?: Maybe<Array<CategoryAdviceModelLinkFieldNonNullMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  category: Scalars['JsonField']['output'];
  id: Scalars['ItemId']['output'];
  link: CategoryAdviceModelLinkField;
  name: Scalars['String']['output'];
};


/** Record of type 💁 Category Advice (category_advice) */
export type CategoryAdviceRecord_AllLinkLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💁 Category Advice (category_advice) */
export type CategoryAdviceRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💁 Category Advice (category_advice) */
export type CategoryAdviceRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💁 Category Advice (category_advice) */
export type CategoryAdviceRecordLinkArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💁 Category Advice (category_advice) */
export type CategoryAdviceRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type CheckoutModelDeliveryInfoLinkField = CustomLinkRecord | PageLinkRecord;

export type CheckoutModelDeliveryInfoLinkFieldMultiLocaleField = {
  __typename: 'CheckoutModelDeliveryInfoLinkFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<CheckoutModelDeliveryInfoLinkField>;
};

export type CheckoutModelGiftInfoLinkField = CustomLinkRecord | PageLinkRecord;

export type CheckoutModelGiftInfoLinkFieldMultiLocaleField = {
  __typename: 'CheckoutModelGiftInfoLinkFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<CheckoutModelGiftInfoLinkField>;
};

/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord = RecordInterface & {
  __typename: 'CheckoutRecord';
  _allAcceptTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allConfirmationTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDeliveryInfoLinkLocales?: Maybe<Array<CheckoutModelDeliveryInfoLinkFieldMultiLocaleField>>;
  _allGiftInfoLinkLocales?: Maybe<Array<CheckoutModelGiftInfoLinkFieldMultiLocaleField>>;
  _allIndexListLocales?: Maybe<Array<BulletListRecordMultiLocaleField>>;
  _allInformationListLocales?: Maybe<Array<BulletListRecordMultiLocaleField>>;
  _allPaymentInfoLocales?: Maybe<Array<PaymentInfoRecordNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  acceptText: Scalars['String']['output'];
  confirmationText: Scalars['String']['output'];
  deliveryInfoLink?: Maybe<CheckoutModelDeliveryInfoLinkField>;
  giftInfoLink?: Maybe<CheckoutModelGiftInfoLinkField>;
  id: Scalars['ItemId']['output'];
  indexList?: Maybe<BulletListRecord>;
  informationList?: Maybe<BulletListRecord>;
  paymentInfo: PaymentInfoRecord;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllAcceptTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllConfirmationTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllDeliveryInfoLinkLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllGiftInfoLinkLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllIndexListLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllInformationListLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_AllPaymentInfoLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordAcceptTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordConfirmationTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordDeliveryInfoLinkArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordGiftInfoLinkArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordIndexListArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordInformationListArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Checkout (checkout) */
export type CheckoutRecordPaymentInfoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type CollectionMetadata = {
  __typename: 'CollectionMetadata';
  count: Scalars['IntType']['output'];
};

export enum ColorBucketType {
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Cyan = 'cyan',
  Green = 'green',
  Grey = 'grey',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow'
}

export type ColorField = {
  __typename: 'ColorField';
  alpha: Scalars['IntType']['output'];
  blue: Scalars['IntType']['output'];
  cssRgb: Scalars['String']['output'];
  green: Scalars['IntType']['output'];
  hex: Scalars['String']['output'];
  red: Scalars['IntType']['output'];
};

/** Block of type ❓ Contact Form (contact_form) */
export type ContactFormRecord = RecordInterface & {
  __typename: 'ContactFormRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  acceptText: Scalars['String']['output'];
  confirmation: NotificationRecord;
  countries: Array<CountryRecord>;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
};


/** Block of type ❓ Contact Form (contact_form) */
export type ContactFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type ❓ Contact Form (contact_form) */
export type ContactFormRecordAcceptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactInfoCtaSectionModelCtaField = CustomLinkRecord | PageLinkRecord;

export type ContactInfoCtaSectionModelRichTextField = {
  __typename: 'ContactInfoCtaSectionModelRichTextField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type ❓ Contact Info CTA section (contact_info_cta_section) */
export type ContactInfoCtaSectionRecord = RecordInterface & {
  __typename: 'ContactInfoCtaSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  cta?: Maybe<ContactInfoCtaSectionModelCtaField>;
  form: ContactFormRecord;
  googleMapsEmbed?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  richText: ContactInfoCtaSectionModelRichTextField;
};


/** Block of type ❓ Contact Info CTA section (contact_info_cta_section) */
export type ContactInfoCtaSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type ❓ Contact Info CTA section (contact_info_cta_section) */
export type ContactInfoCtaSectionRecordGoogleMapsEmbedArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentSectionModelBodyBlocksField = BannerItemRecord | ImageBlockRecord | SplitItemRecord;

export type ContentSectionModelBodyField = {
  __typename: 'ContentSectionModelBodyField';
  blocks: Array<ContentSectionModelBodyBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type ✍️ Content section (content_section) */
export type ContentSectionRecord = RecordInterface & {
  __typename: 'ContentSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  body?: Maybe<ContentSectionModelBodyField>;
  id: Scalars['ItemId']['output'];
};


/** Block of type ✍️ Content section (content_section) */
export type ContentSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ContentSectionRecordListListNonNullMultiLocaleField = {
  __typename: 'ContentSectionRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<ContentSectionRecord>;
};

export type CountryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isoCode?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
};

export enum CountryModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsoCodeAsc = 'isoCode_ASC',
  IsoCodeDesc = 'isoCode_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

/** Record of type 🗾 Country (country) */
export type CountryRecord = RecordInterface & {
  __typename: 'CountryRecord';
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
};


/** Record of type 🗾 Country (country) */
export type CountryRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🗾 Country (country) */
export type CountryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🗾 Country (country) */
export type CountryRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Block of type 🤝 Custom Link (custom_link) */
export type CustomLinkRecord = RecordInterface & {
  __typename: 'CustomLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  highlight: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  optLinkUrl?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};


/** Block of type 🤝 Custom Link (custom_link) */
export type CustomLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CustomLinkRecordListListNonNullMultiLocaleField = {
  __typename: 'CustomLinkRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<CustomLinkRecord>;
};

/** Specifies how to filter Date fields */
export type DateFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['Date']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['Date']['input']>;
};

/** Block of type 📅 Dates (date) */
export type DateRecord = RecordInterface & {
  __typename: 'DateRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Date']['output'];
};


/** Block of type 📅 Dates (date) */
export type DateRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🚢 Discovery section (discovery_section) */
export type DiscoverySectionRecord = RecordInterface & {
  __typename: 'DiscoverySectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkColumns: Array<LinkColumnRecord>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🚢 Discovery section (discovery_section) */
export type DiscoverySectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🚢 Discovery section (discovery_section) */
export type DiscoverySectionRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DomainModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<DomainModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DomainModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  bannerImage?: InputMaybe<FileFilter>;
  bannerLinkText?: InputMaybe<StringFilter>;
  bannerText?: InputMaybe<TextFilter>;
  bannerTitle?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  images?: InputMaybe<GalleryFilter>;
  logo?: InputMaybe<FileFilter>;
  name?: InputMaybe<StringFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  text?: InputMaybe<StructuredTextFilter>;
};

export enum DomainModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BannerLinkTextAsc = 'bannerLinkText_ASC',
  BannerLinkTextDesc = 'bannerLinkText_DESC',
  BannerTitleAsc = 'bannerTitle_ASC',
  BannerTitleDesc = 'bannerTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type DomainModelTextField = {
  __typename: 'DomainModelTextField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type DomainModelTextFieldMultiLocaleField = {
  __typename: 'DomainModelTextFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<DomainModelTextField>;
};

/** Record of type 🏫 Domain (domain) */
export type DomainRecord = RecordInterface & {
  __typename: 'DomainRecord';
  _allBannerLinkTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allBannerTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allBannerTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<DomainModelTextFieldMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  bannerImage?: Maybe<ImageFileField>;
  bannerLinkText?: Maybe<Scalars['String']['output']>;
  bannerText?: Maybe<Scalars['String']['output']>;
  bannerTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  images: Array<ImageFileField>;
  logo: ImageFileField;
  name: Scalars['String']['output'];
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  text?: Maybe<DomainModelTextField>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllBannerLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllBannerTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllBannerTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordBannerLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordBannerTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordBannerTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏫 Domain (domain) */
export type DomainRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🍾 Engraving Form (engraving_form) */
export type EngravingFormRecord = RecordInterface & {
  __typename: 'EngravingFormRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  acceptText: Scalars['String']['output'];
  confirmation: NotificationRecord;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🍾 Engraving Form (engraving_form) */
export type EngravingFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🍾 Engraving Form (engraving_form) */
export type EngravingFormRecordAcceptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🍾 Engraving section (engraving_section) */
export type EngravingSectionRecord = RecordInterface & {
  __typename: 'EngravingSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  form: EngravingFormRecord;
  id: Scalars['ItemId']['output'];
  optImage?: Maybe<ImageFileField>;
  optText?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🍾 Engraving section (engraving_section) */
export type EngravingSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🍾 Engraving section (engraving_section) */
export type EngravingSectionRecordOptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Error404ModelBodyField = {
  __typename: 'Error404ModelBodyField';
  blocks: Array<ImageBlockRecord>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type Error404ModelBodyFieldNonNullMultiLocaleField = {
  __typename: 'Error404ModelBodyFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Error404ModelBodyField;
};

/** Record of type ⚠️ Error404 (error404) */
export type Error404Record = RecordInterface & {
  __typename: 'Error404Record';
  _allBodyLocales?: Maybe<Array<Error404ModelBodyFieldNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  body: Error404ModelBodyField;
  id: Scalars['ItemId']['output'];
};


/** Record of type ⚠️ Error404 (error404) */
export type Error404Record_AllBodyLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⚠️ Error404 (error404) */
export type Error404Record_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⚠️ Error404 (error404) */
export type Error404RecordBodyArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum FaviconType {
  AppleTouchIcon = 'appleTouchIcon',
  Icon = 'icon',
  MsApplication = 'msApplication'
}

export type FileField = FileFieldInterface & {
  __typename: 'FileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};


export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};


export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

/** Specifies how to filter Multiple files/images field */
export type GalleryFilter = {
  /** Filter records that have all of the specified uploads. The specified values must be Upload IDs */
  allIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Filter records that have one of the specified uploads. The specified values must be Upload IDs */
  anyIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Search for records with an exact match. The specified values must be Upload IDs */
  eq?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that do not have any of the specified uploads. The specified values must be Upload IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

export type GalleryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<GalleryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GalleryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  addToGalleryPage?: InputMaybe<BooleanFilter>;
  date?: InputMaybe<DateFilter>;
  displayType?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<ItemIdFilter>;
  images?: InputMaybe<GalleryFilter>;
  optTeaserImage?: InputMaybe<FileFilter>;
  optTeaserLinkText?: InputMaybe<StringFilter>;
  optTeaserTitle?: InputMaybe<StringFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum GalleryModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  AddToGalleryPageAsc = 'addToGalleryPage_ASC',
  AddToGalleryPageDesc = 'addToGalleryPage_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OptTeaserLinkTextAsc = 'optTeaserLinkText_ASC',
  OptTeaserLinkTextDesc = 'optTeaserLinkText_DESC',
  OptTeaserTitleAsc = 'optTeaserTitle_ASC',
  OptTeaserTitleDesc = 'optTeaserTitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord = RecordInterface & {
  __typename: 'GalleryRecord';
  _allOptTeaserLinkTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allOptTeaserTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allSectionsLocales?: Maybe<Array<ContentSectionRecordListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  addToGalleryPage: Scalars['BooleanType']['output'];
  date: Scalars['Date']['output'];
  displayType: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  images: Array<ImageFileField>;
  optTeaserImage?: Maybe<ImageFileField>;
  optTeaserLinkText?: Maybe<Scalars['String']['output']>;
  optTeaserTitle?: Maybe<Scalars['String']['output']>;
  sections: Array<ContentSectionRecord>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_AllOptTeaserLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_AllOptTeaserTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecordOptTeaserLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecordOptTeaserTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🖼️ Gallery (gallery) */
export type GalleryRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🖼️ Gallery section (gallery_section) */
export type GallerySectionRecord = RecordInterface & {
  __typename: 'GallerySectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  gallery: GalleryRecord;
  id: Scalars['ItemId']['output'];
};


/** Block of type 🖼️ Gallery section (gallery_section) */
export type GallerySectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type GlobalSeoField = {
  __typename: 'GlobalSeoField';
  facebookPageUrl?: Maybe<Scalars['String']['output']>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars['String']['output']>;
  titleSuffix?: Maybe<Scalars['String']['output']>;
  twitterAccount?: Maybe<Scalars['String']['output']>;
};

/** Block of type 🕑 Hours (hour) */
export type HourRecord = RecordInterface & {
  __typename: 'HourRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  dayOfWeek: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  text: Scalars['String']['output'];
};


/** Block of type 🕑 Hours (hour) */
export type HourRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type IconLinkModelLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🤝 Icon Link (icon_link) */
export type IconLinkRecord = RecordInterface & {
  __typename: 'IconLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  linkItem: IconLinkModelLinkItemField;
};


/** Block of type 🤝 Icon Link (icon_link) */
export type IconLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type IconLinkRecordListListNonNullMultiLocaleField = {
  __typename: 'IconLinkRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<IconLinkRecord>;
};

/** Block of type 🌅 Image Block (image_block) */
export type ImageBlockRecord = RecordInterface & {
  __typename: 'ImageBlockRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
};


/** Block of type 🌅 Image Block (image_block) */
export type ImageBlockRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImageFileField = FileFieldInterface & {
  __typename: 'ImageFileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint: FocalPoint;
  format: Scalars['String']['output'];
  height: Scalars['IntType']['output'];
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage: ResponsiveImage;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width: Scalars['IntType']['output'];
};


export type ImageFileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type ImageFileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type ImageFileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type ImageLinkModelLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🤝 Image Link (image_link) */
export type ImageLinkRecord = RecordInterface & {
  __typename: 'ImageLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  linkItem: ImageLinkModelLinkItemField;
};


/** Block of type 🤝 Image Link (image_link) */
export type ImageLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars['String']['input']>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars['String']['input']>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos?: InputMaybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs?: InputMaybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: InputMaybe<Array<ImgixParamsFillGradientLinearDirection>>;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType?: InputMaybe<ImgixParamsFillGradientType>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: InputMaybe<Scalars['String']['input']>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars['String']['input']>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars['String']['input']>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars['String']['input']>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars['String']['input']>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars['FloatType']['input']>;
};

export enum ImgixParamsAuto {
  Compress = 'compress',
  Enhance = 'enhance',
  Format = 'format',
  Redeye = 'redeye'
}

export enum ImgixParamsBlendAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendCrop {
  Bottom = 'bottom',
  Faces = 'faces',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsBlendMode {
  Burn = 'burn',
  Color = 'color',
  Darken = 'darken',
  Difference = 'difference',
  Dodge = 'dodge',
  Exclusion = 'exclusion',
  Hardlight = 'hardlight',
  Hue = 'hue',
  Lighten = 'lighten',
  Luminosity = 'luminosity',
  Multiply = 'multiply',
  Normal = 'normal',
  Overlay = 'overlay',
  Saturation = 'saturation',
  Screen = 'screen',
  Softlight = 'softlight'
}

export enum ImgixParamsBlendSize {
  Inherit = 'inherit'
}

export enum ImgixParamsCh {
  Dpr = 'dpr',
  SaveData = 'saveData',
  Width = 'width'
}

export enum ImgixParamsCrop {
  Bottom = 'bottom',
  Edges = 'edges',
  Entropy = 'entropy',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsCs {
  Adobergb1998 = 'adobergb1998',
  Srgb = 'srgb',
  Strip = 'strip',
  Tinysrgb = 'tinysrgb'
}

export enum ImgixParamsFill {
  Blur = 'blur',
  Gen = 'gen',
  Generative = 'generative',
  Gradient = 'gradient',
  Solid = 'solid'
}

export enum ImgixParamsFillGenPos {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientCs {
  Hsl = 'hsl',
  Lch = 'lch',
  Linear = 'linear',
  Oklab = 'oklab',
  Srgb = 'srgb'
}

export enum ImgixParamsFillGradientLinearDirection {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientType {
  Linear = 'linear',
  Radial = 'radial'
}

export enum ImgixParamsFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Facearea = 'facearea',
  Fill = 'fill',
  Fillmax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale'
}

export enum ImgixParamsFlip {
  H = 'h',
  Hv = 'hv',
  V = 'v'
}

export enum ImgixParamsFm {
  Avif = 'avif',
  Blurhash = 'blurhash',
  Gif = 'gif',
  Jp2 = 'jp2',
  Jpg = 'jpg',
  Json = 'json',
  Jxr = 'jxr',
  Mp4 = 'mp4',
  Pjpg = 'pjpg',
  Png = 'png',
  Png8 = 'png8',
  Png32 = 'png32',
  Webm = 'webm',
  Webp = 'webp'
}

export enum ImgixParamsIptc {
  Allow = 'allow',
  Block = 'block'
}

export enum ImgixParamsMarkAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsMarkFit {
  Clip = 'clip',
  Crop = 'crop',
  Fill = 'fill',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsMarkTile {
  Grid = 'grid'
}

export enum ImgixParamsPalette {
  Css = 'css',
  Json = 'json'
}

export enum ImgixParamsTransparency {
  Grid = 'grid'
}

export enum ImgixParamsTrim {
  Auto = 'auto',
  Color = 'color'
}

export enum ImgixParamsTxtAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsTxtClip {
  Ellipsis = 'ellipsis',
  End = 'end',
  Middle = 'middle',
  Start = 'start'
}

export enum ImgixParamsTxtFit {
  Max = 'max'
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

export enum ItemStatus {
  Draft = 'draft',
  Published = 'published',
  Updated = 'updated'
}

export type JsonFieldNonNullMultiLocaleField = {
  __typename: 'JsonFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Scalars['JsonField']['output'];
};

/** Specifies how to filter JSON fields */
export type JsonFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type LayoutModelMainMenuField = CustomLinkRecord | IconLinkRecord | MenuLinkRecord | PageLinkRecord;

export type LayoutModelMainMenuFieldListListNonNullMultiLocaleField = {
  __typename: 'LayoutModelMainMenuFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LayoutModelMainMenuField>;
};

export type LayoutModelTopMenuLeftField = CustomLinkRecord | PageLinkRecord;

export type LayoutModelTopMenuLeftFieldListListNonNullMultiLocaleField = {
  __typename: 'LayoutModelTopMenuLeftFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LayoutModelTopMenuLeftField>;
};

export type LayoutModelTopMenuRightField = CustomLinkRecord | IconLinkRecord | PageLinkRecord;

export type LayoutModelTopMenuRightFieldListListNonNullMultiLocaleField = {
  __typename: 'LayoutModelTopMenuRightFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LayoutModelTopMenuRightField>;
};

/** Record of type 🧩 Layout (layout) */
export type LayoutRecord = RecordInterface & {
  __typename: 'LayoutRecord';
  _allLinkColumnsLocales?: Maybe<Array<LinkColumnRecordListListNonNullMultiLocaleField>>;
  _allMainMenuLocales?: Maybe<Array<LayoutModelMainMenuFieldListListNonNullMultiLocaleField>>;
  _allNewsletterSubscriptionLocales?: Maybe<Array<NewsletterSubscriptionRecordMultiLocaleField>>;
  _allPaymentInfoLocales?: Maybe<Array<PaymentInfoRecordMultiLocaleField>>;
  _allSocialMediaLocales?: Maybe<Array<IconLinkRecordListListNonNullMultiLocaleField>>;
  _allTopBarLocales?: Maybe<Array<IconLinkRecordListListNonNullMultiLocaleField>>;
  _allTopMenuLeftLocales?: Maybe<Array<LayoutModelTopMenuLeftFieldListListNonNullMultiLocaleField>>;
  _allTopMenuRightLocales?: Maybe<Array<LayoutModelTopMenuRightFieldListListNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkColumns: Array<LinkColumnRecord>;
  mainMenu: Array<LayoutModelMainMenuField>;
  newsletterSubscription?: Maybe<NewsletterSubscriptionRecord>;
  paymentInfo?: Maybe<PaymentInfoRecord>;
  socialMedia: Array<IconLinkRecord>;
  topBar: Array<IconLinkRecord>;
  topMenuLeft: Array<LayoutModelTopMenuLeftField>;
  topMenuRight: Array<LayoutModelTopMenuRightField>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllLinkColumnsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllMainMenuLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllNewsletterSubscriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllPaymentInfoLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllSocialMediaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllTopBarLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllTopMenuLeftLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllTopMenuRightLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordLinkColumnsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordMainMenuArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordNewsletterSubscriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordPaymentInfoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordSocialMediaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordTopBarArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordTopMenuLeftArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordTopMenuRightArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type LinkColumnModelLinkItemsField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🔡 Link Column (link_column) */
export type LinkColumnRecord = RecordInterface & {
  __typename: 'LinkColumnRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkItems: Array<LinkColumnModelLinkItemsField>;
  title: Scalars['String']['output'];
};


/** Block of type 🔡 Link Column (link_column) */
export type LinkColumnRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type LinkColumnRecordListListNonNullMultiLocaleField = {
  __typename: 'LinkColumnRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LinkColumnRecord>;
};

/** Specifies how to filter by locale */
export type LocalesFilter = {
  /** Filter records that are localized in all the specified locales */
  allIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are localized in at least one of the specified locales */
  anyIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are not localized in any of the specified locales */
  notIn?: InputMaybe<Array<SiteLocale>>;
};

export type MenuLinkModelLinkItemField = CustomLinkRecord | PageLinkRecord;

export type MenuLinkModelSubLinkItemsField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🤝 Menu Link (menu_link) */
export type MenuLinkRecord = RecordInterface & {
  __typename: 'MenuLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkItem: MenuLinkModelLinkItemField;
  subLinkItems: Array<MenuLinkModelSubLinkItemsField>;
};


/** Block of type 🤝 Menu Link (menu_link) */
export type MenuLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum MuxThumbnailFormatType {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png'
}

export type NewsletterModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewsletterModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsletterModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  buttonText?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  inputPlaceholder?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NewsletterModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputPlaceholderAsc = 'inputPlaceholder_ASC',
  InputPlaceholderDesc = 'inputPlaceholder_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord = RecordInterface & {
  __typename: 'NewsletterRecord';
  _allButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allConfirmationLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allExceptionLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allInputPlaceholderLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  buttonText: Scalars['String']['output'];
  confirmation: NotificationRecord;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
  inputPlaceholder?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord_AllButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord_AllConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord_AllExceptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord_AllInputPlaceholderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecordButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecordExceptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecordInputPlaceholderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter (newsletter) */
export type NewsletterRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord = RecordInterface & {
  __typename: 'NewsletterSubscriptionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  newsletter: NewsletterRecord;
};


/** Block of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type NewsletterSubscriptionRecordMultiLocaleField = {
  __typename: 'NewsletterSubscriptionRecordMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<NewsletterSubscriptionRecord>;
};

/** Block of type 🔔 Notification (notification) */
export type NotificationRecord = RecordInterface & {
  __typename: 'NotificationRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};


/** Block of type 🔔 Notification (notification) */
export type NotificationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🔔 Notification (notification) */
export type NotificationRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationRecordNonNullMultiLocaleField = {
  __typename: 'NotificationRecordNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: NotificationRecord;
};

/** Record of type 🏢 Organization (organization) */
export type OrganizationRecord = RecordInterface & {
  __typename: 'OrganizationRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  city: Scalars['String']['output'];
  closingDays: Array<DateRecord>;
  countryCode: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  openingHours: Array<HourRecord>;
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  street: Scalars['String']['output'];
};


/** Record of type 🏢 Organization (organization) */
export type OrganizationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

/** Block of type 🤝 Page Link (page_link) */
export type PageLinkRecord = RecordInterface & {
  __typename: 'PageLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  highlight: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  link: PageRecord;
  text: Scalars['String']['output'];
};


/** Block of type 🤝 Page Link (page_link) */
export type PageLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type PageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum PageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type PageModelSectionsField = AllDomainsSectionRecord | AllGalleriesSectionRecord | ArticlesSectionRecord | BannerSectionRecord | CategoriesSectionRecord | ContactInfoCtaSectionRecord | ContentSectionRecord | DiscoverySectionRecord | EngravingSectionRecord | GallerySectionRecord | ProductsSectionRecord | SplitSectionRecord | SwiperInfoCtaSectionRecord | UspSectionRecord;

export type PageModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'PageModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<PageModelSectionsField>;
};

/** Record of type 🏡 Page (page) */
export type PageRecord = RecordInterface & {
  __typename: 'PageRecord';
  _allSectionsLocales?: Maybe<Array<PageModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sections: Array<PageModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 💳 Payment Info (payment_info) */
export type PaymentInfoRecord = RecordInterface & {
  __typename: 'PaymentInfoRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  paymentProviderLogo: Array<ImageFileField>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 💳 Payment Info (payment_info) */
export type PaymentInfoRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 💳 Payment Info (payment_info) */
export type PaymentInfoRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentInfoRecordMultiLocaleField = {
  __typename: 'PaymentInfoRecordMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<PaymentInfoRecord>;
};

export type PaymentInfoRecordNonNullMultiLocaleField = {
  __typename: 'PaymentInfoRecordNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: PaymentInfoRecord;
};

export type PopularCategoryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<PopularCategoryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PopularCategoryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  category?: InputMaybe<JsonFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
};

export enum PopularCategoryModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Record of type 📊 Popular Category (popular_category) */
export type PopularCategoryRecord = RecordInterface & {
  __typename: 'PopularCategoryRecord';
  _allLinksLocales?: Maybe<Array<CustomLinkRecordListListNonNullMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  category: Scalars['JsonField']['output'];
  id: Scalars['ItemId']['output'];
  links: Array<CustomLinkRecord>;
  name: Scalars['String']['output'];
};


/** Record of type 📊 Popular Category (popular_category) */
export type PopularCategoryRecord_AllLinksLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📊 Popular Category (popular_category) */
export type PopularCategoryRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📊 Popular Category (popular_category) */
export type PopularCategoryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📊 Popular Category (popular_category) */
export type PopularCategoryRecordLinksArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📊 Popular Category (popular_category) */
export type PopularCategoryRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecord = RecordInterface & {
  __typename: 'ProductAvailabilityFormRecord';
  _allAcceptTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allConfirmationLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allExceptionLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  acceptText: Scalars['String']['output'];
  confirmation: NotificationRecord;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecord_AllAcceptTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecord_AllConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecord_AllExceptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecordAcceptTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecordExceptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📦 Product Availability Form (product_availability_form) */
export type ProductAvailabilityFormRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 📦 Products section (products_section) */
export type ProductsSectionRecord = RecordInterface & {
  __typename: 'ProductsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Scalars['JsonField']['output']>;
};


/** Block of type 📦 Products section (products_section) */
export type ProductsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The query root for this schema */
export type Query = {
  __typename: 'Query';
  /** Returns meta information regarding a record collection */
  _allCategoryAdvicesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCountriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allDomainsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allGalleriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNewslettersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPopularCategoriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTranslationsMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allUspsMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns the single instance record */
  accountPage?: Maybe<AccountPageRecord>;
  /** Returns the single instance record */
  addressForm?: Maybe<AddressFormRecord>;
  /** Returns a collection of records */
  allCategoryAdvices: Array<CategoryAdviceRecord>;
  /** Returns a collection of records */
  allCountries: Array<CountryRecord>;
  /** Returns a collection of records */
  allDomains: Array<DomainRecord>;
  /** Returns a collection of records */
  allGalleries: Array<GalleryRecord>;
  /** Returns a collection of records */
  allNewsletters: Array<NewsletterRecord>;
  /** Returns a collection of records */
  allPages: Array<PageRecord>;
  /** Returns a collection of records */
  allPopularCategories: Array<PopularCategoryRecord>;
  /** Returns a collection of records */
  allTranslations: Array<TranslationRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a collection of records */
  allUsps: Array<UspRecord>;
  /** Returns a specific record */
  categoryAdvice?: Maybe<CategoryAdviceRecord>;
  /** Returns the single instance record */
  checkout?: Maybe<CheckoutRecord>;
  /** Returns a specific record */
  country?: Maybe<CountryRecord>;
  /** Returns a specific record */
  domain?: Maybe<DomainRecord>;
  /** Returns the single instance record */
  error404?: Maybe<Error404Record>;
  /** Returns a specific record */
  gallery?: Maybe<GalleryRecord>;
  /** Returns the single instance record */
  layout?: Maybe<LayoutRecord>;
  /** Returns a specific record */
  newsletter?: Maybe<NewsletterRecord>;
  /** Returns the single instance record */
  organization?: Maybe<OrganizationRecord>;
  /** Returns a specific record */
  page?: Maybe<PageRecord>;
  /** Returns a specific record */
  popularCategory?: Maybe<PopularCategoryRecord>;
  /** Returns the single instance record */
  productAvailabilityForm?: Maybe<ProductAvailabilityFormRecord>;
  /** Returns the single instance record */
  redirectMap?: Maybe<RedirectMapRecord>;
  /** Returns the single instance record */
  registrationForm?: Maybe<RegistrationFormRecord>;
  /** Returns a specific record */
  translation?: Maybe<TranslationRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  /** Returns a specific record */
  usp?: Maybe<UspRecord>;
};


/** The query root for this schema */
export type Query_AllCategoryAdvicesMetaArgs = {
  filter?: InputMaybe<CategoryAdviceModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllCountriesMetaArgs = {
  filter?: InputMaybe<CountryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllDomainsMetaArgs = {
  filter?: InputMaybe<DomainModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllGalleriesMetaArgs = {
  filter?: InputMaybe<GalleryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllNewslettersMetaArgs = {
  filter?: InputMaybe<NewsletterModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllPagesMetaArgs = {
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllPopularCategoriesMetaArgs = {
  filter?: InputMaybe<PopularCategoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTranslationsMetaArgs = {
  filter?: InputMaybe<TranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllUspsMetaArgs = {
  filter?: InputMaybe<UspModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryAccountPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryAddressFormArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryAllCategoryAdvicesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryAdviceModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CategoryAdviceModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllCountriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CountryModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllDomainsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<DomainModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<DomainModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllGalleriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GalleryModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GalleryModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllNewslettersArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsletterModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsletterModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllPopularCategoriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PopularCategoryModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PopularCategoryModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TranslationModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllUspsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UspModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UspModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryCategoryAdviceArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryAdviceModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CategoryAdviceModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryCheckoutArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryCountryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CountryModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryDomainArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<DomainModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<DomainModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryError404Args = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryGalleryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GalleryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GalleryModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryLayoutArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryNewsletterArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsletterModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsletterModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryOrganizationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryPopularCategoryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PopularCategoryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PopularCategoryModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryProductAvailabilityFormArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryRedirectMapArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryRegistrationFormArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryTranslationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TranslationModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};


/** The query root for this schema */
export type QueryUspArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UspModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UspModelOrderBy>>>;
};

export type RecordInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type ↔️ Redirect map (redirect_map) */
export type RedirectMapRecord = RecordInterface & {
  __typename: 'RedirectMapRecord';
  _allRedirectsLocales?: Maybe<Array<JsonFieldNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  redirects: Scalars['JsonField']['output'];
};


/** Record of type ↔️ Redirect map (redirect_map) */
export type RedirectMapRecord_AllRedirectsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ↔️ Redirect map (redirect_map) */
export type RedirectMapRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ↔️ Redirect map (redirect_map) */
export type RedirectMapRecordRedirectsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord = RecordInterface & {
  __typename: 'RegistrationFormRecord';
  _allConfirmationLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allDuplicateLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allExceptionLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allSelectB2bTypeLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allSelectB2cTypeLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allSelectIntroHtmlLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  confirmation: NotificationRecord;
  countries: Array<CountryRecord>;
  duplicate: NotificationRecord;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
  selectB2bType: Scalars['String']['output'];
  selectB2cType: Scalars['String']['output'];
  selectIntroHtml?: Maybe<Scalars['String']['output']>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllDuplicateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllExceptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllSelectB2bTypeLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllSelectB2cTypeLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllSelectIntroHtmlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordDuplicateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordExceptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordSelectB2bTypeArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordSelectB2cTypeArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordSelectIntroHtmlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Registration Form (registration_form) */
export type RegistrationFormRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  Icon = 'icon',
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export type ResponsiveImage = {
  __typename: 'ResponsiveImage';
  alt?: Maybe<Scalars['String']['output']>;
  aspectRatio: Scalars['FloatType']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  bgColor?: Maybe<Scalars['String']['output']>;
  height: Scalars['IntType']['output'];
  sizes: Scalars['String']['output'];
  src: Scalars['String']['output'];
  srcSet: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  webpSrcSet: Scalars['String']['output'];
  width: Scalars['IntType']['output'];
};

export type SeoField = {
  __typename: 'SeoField';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<FileField>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterCard?: Maybe<Scalars['String']['output']>;
};

export type SeoFieldMultiLocaleField = {
  __typename: 'SeoFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<SeoField>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type Site = {
  __typename: 'Site';
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
};


export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};


export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  NlBe = 'nl_BE'
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that have one of the specified slugs */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do have one of the specified slugs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SplitItemModelOptLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type ✂️ Split Item (split_item) */
export type SplitItemRecord = RecordInterface & {
  __typename: 'SplitItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  imagePosition: Scalars['BooleanType']['output'];
  optLinkItem?: Maybe<SplitItemModelOptLinkItemField>;
  optTitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};


/** Block of type ✂️ Split Item (split_item) */
export type SplitItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type ✂️ Split Item (split_item) */
export type SplitItemRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type ✂️ Split section (split_section) */
export type SplitSectionRecord = RecordInterface & {
  __typename: 'SplitSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  splitItems: Array<SplitItemRecord>;
};


/** Block of type ✂️ Split section (split_section) */
export type SplitSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars['BooleanType']['input']>;
  pattern: Scalars['String']['input'];
  regexp?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type StringMultiLocaleField = {
  __typename: 'StringMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StringNonNullMultiLocaleField = {
  __typename: 'StringNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Scalars['String']['output'];
};

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type SwiperInfoCtaSectionModelCtaField = CustomLinkRecord | PageLinkRecord;

export type SwiperInfoCtaSectionModelRichTextField = {
  __typename: 'SwiperInfoCtaSectionModelRichTextField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type ⏩ Swiper Info CTA section (swiper_info_cta_section) */
export type SwiperInfoCtaSectionRecord = RecordInterface & {
  __typename: 'SwiperInfoCtaSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  cta?: Maybe<SwiperInfoCtaSectionModelCtaField>;
  id: Scalars['ItemId']['output'];
  images: Array<ImageLinkRecord>;
  richText: SwiperInfoCtaSectionModelRichTextField;
};


/** Block of type ⏩ Swiper Info CTA section (swiper_info_cta_section) */
export type SwiperInfoCtaSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Tag = {
  __typename: 'Tag';
  attributes?: Maybe<Scalars['MetaTagAttributes']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  tag: Scalars['String']['output'];
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type TranslationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TranslationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TranslationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  comment?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export enum TranslationModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

/** Record of type 🌍 Translation (translation) */
export type TranslationRecord = RecordInterface & {
  __typename: 'TranslationRecord';
  _allValueLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecord_AllValueLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecordValueArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BasenameAsc = 'basename_ASC',
  BasenameDesc = 'basename_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  ResolutionAsc = 'resolution_ASC',
  ResolutionDesc = 'resolution_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC'
}

export enum UploadOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square'
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  Archive = 'archive',
  Audio = 'audio',
  Image = 'image',
  Pdfdocument = 'pdfdocument',
  Presentation = 'presentation',
  Richtext = 'richtext',
  Spreadsheet = 'spreadsheet',
  Video = 'video'
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UploadVideoField = {
  __typename: 'UploadVideoField';
  alt?: Maybe<Scalars['String']['output']>;
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  framerate?: Maybe<Scalars['Int']['output']>;
  height: Scalars['IntType']['output'];
  mp4Url?: Maybe<Scalars['String']['output']>;
  muxAssetId: Scalars['String']['output'];
  muxPlaybackId: Scalars['String']['output'];
  streamingUrl: Scalars['String']['output'];
  thumbhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  width: Scalars['IntType']['output'];
};


export type UploadVideoFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};


export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};


export type UploadVideoFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

export type UspModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<UspModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UspModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  title?: InputMaybe<StringFilter>;
};

export type UspModelLinkItemField = CustomLinkRecord | PageLinkRecord;

export type UspModelLinkItemFieldMultiLocaleField = {
  __typename: 'UspModelLinkItemFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<UspModelLinkItemField>;
};

export enum UspModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type ⭐ USP (usp) */
export type UspRecord = RecordInterface & {
  __typename: 'UspRecord';
  _allLinkItemLocales?: Maybe<Array<UspModelLinkItemFieldMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  linkItem?: Maybe<UspModelLinkItemField>;
  title: Scalars['String']['output'];
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllLinkItemLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordLinkItemArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type ⭐ USP section (usp_section) */
export type UspSectionRecord = RecordInterface & {
  __typename: 'UspSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  uspItems: Array<UspRecord>;
};


/** Block of type ⭐ USP section (usp_section) */
export type UspSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum VideoMp4Res {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type FocalPoint = {
  __typename: 'focalPoint';
  x: Scalars['FloatType']['output'];
  y: Scalars['FloatType']['output'];
};

export type Custom_AllDomainsSectionRecordFragment_AllDomainsSectionRecord_domains_DomainRecord_logo_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_AllDomainsSectionRecordFragment_AllDomainsSectionRecord_domains_DomainRecord_logo_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_AllDomainsSectionRecordFragment_AllDomainsSectionRecord_domains_DomainRecord_logo_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_AllDomainsSectionRecordFragment_AllDomainsSectionRecord_domains_DomainRecord = { __typename: 'DomainRecord', id: string, name: string, slug: string, logo: Custom_AllDomainsSectionRecordFragment_AllDomainsSectionRecord_domains_DomainRecord_logo_ImageFileField };

export type Custom_AllDomainsSectionRecordFragment = { __typename: 'AllDomainsSectionRecord', id: string, domains: Array<Custom_AllDomainsSectionRecordFragment_AllDomainsSectionRecord_domains_DomainRecord> };

export type Custom_AllGalleriesSectionRecordFragment = { __typename: 'AllGalleriesSectionRecord', id: string };

export type Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_linkItem = Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_linkItem_CustomLinkRecord | Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_linkItem_PageLinkRecord;

export type Custom_ArticleTeaserRecordFragment = { __typename: 'ArticleTeaserRecord', id: string, title: string, image: Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_image_ImageFileField, linkItem: Custom_ArticleTeaserRecordFragment_ArticleTeaserRecord_linkItem };

export type Custom_ArticlesSectionRecordFragment_ArticlesSectionRecord_teasers_ArticleTeaserRecord = (
  { __typename: 'ArticleTeaserRecord' }
  & Custom_ArticleTeaserRecordFragment
);

export type Custom_ArticlesSectionRecordFragment = { __typename: 'ArticlesSectionRecord', id: string, teasers: Array<Custom_ArticlesSectionRecordFragment_ArticlesSectionRecord_teasers_ArticleTeaserRecord> };

export type Custom_BannerItemRecordFragment_BannerItemRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_BannerItemRecordFragment_BannerItemRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_BannerItemRecordFragment_BannerItemRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_BannerItemRecordFragment = { __typename: 'BannerItemRecord', _modelApiKey: string, id: string, image: Custom_BannerItemRecordFragment_BannerItemRecord_image_ImageFileField };

export type Custom_BannerSectionRecordFragment_BannerSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_BannerSectionRecordFragment_BannerSectionRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_BannerSectionRecordFragment_BannerSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_BannerSectionRecordFragment = { __typename: 'BannerSectionRecord', id: string, optTitle?: string | null, optText?: string | null, image: Custom_BannerSectionRecordFragment_BannerSectionRecord_image_ImageFileField };

export type Custom_BulletListRecordFragment = { __typename: 'BulletListRecord', id: string, optTitle?: string | null, list: string };

export type Custom_ContactFormRecordFragment_ContactFormRecord_countries_CountryRecord = { __typename: 'CountryRecord', id: string, name: string, isoCode: string };

export type Custom_ContactFormRecordFragment_ContactFormRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type Custom_ContactFormRecordFragment_ContactFormRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type Custom_ContactFormRecordFragment = { __typename: 'ContactFormRecord', id: string, acceptText: string, countries: Array<Custom_ContactFormRecordFragment_ContactFormRecord_countries_CountryRecord>, confirmation: Custom_ContactFormRecordFragment_ContactFormRecord_confirmation_NotificationRecord, exception: Custom_ContactFormRecordFragment_ContactFormRecord_exception_NotificationRecord };

export type Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_richText_ContactInfoCtaSectionModelRichTextField = { __typename: 'ContactInfoCtaSectionModelRichTextField', blocks: Array<string>, links: Array<string>, value: unknown };

export type Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_cta_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_cta_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_cta = Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_cta_CustomLinkRecord | Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_cta_PageLinkRecord;

export type Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_form_ContactFormRecord = (
  { __typename: 'ContactFormRecord' }
  & Custom_ContactFormRecordFragment
);

export type Custom_ContactInfoCtaSectionRecordFragment = { __typename: 'ContactInfoCtaSectionRecord', id: string, googleMapsEmbed?: string | null, richText: Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_richText_ContactInfoCtaSectionModelRichTextField, cta?: Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_cta | null, form: Custom_ContactInfoCtaSectionRecordFragment_ContactInfoCtaSectionRecord_form_ContactFormRecord };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_BannerItemRecord = (
  { __typename: 'BannerItemRecord' }
  & Custom_BannerItemRecordFragment
);

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord = (
  { __typename: 'ImageBlockRecord' }
  & Custom_ImageBlockRecordFragment
);

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_SplitItemRecord = (
  { __typename: 'SplitItemRecord' }
  & Custom_SplitItemRecordFragment
);

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks = Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_BannerItemRecord | Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord | Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_SplitItemRecord;

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField = { __typename: 'ContentSectionModelBodyField', links: Array<string>, value: unknown, blocks: Array<Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks> };

export type Custom_ContentSectionRecordFragment = { __typename: 'ContentSectionRecord', id: string, body?: Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField | null };

export type Custom_CustomLinkRecordFragment = { __typename: 'CustomLinkRecord', _modelApiKey: string, id: string, text: string, optLinkUrl?: string | null, highlight: boolean };

export type Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord_linkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord_linkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord_linkItems = Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord_linkItems_CustomLinkRecord | Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord_linkItems_PageLinkRecord;

export type Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord = { __typename: 'LinkColumnRecord', id: string, title: string, linkItems: Array<Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord_linkItems> };

export type Custom_DiscoverySectionRecordFragment = { __typename: 'DiscoverySectionRecord', id: string, title: string, text: string, linkColumns: Array<Custom_DiscoverySectionRecordFragment_DiscoverySectionRecord_linkColumns_LinkColumnRecord> };

export type Custom_EngravingFormRecordFragment_EngravingFormRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type Custom_EngravingFormRecordFragment_EngravingFormRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type Custom_EngravingFormRecordFragment = { __typename: 'EngravingFormRecord', id: string, title: string, acceptText: string, confirmation: Custom_EngravingFormRecordFragment_EngravingFormRecord_confirmation_NotificationRecord, exception: Custom_EngravingFormRecordFragment_EngravingFormRecord_exception_NotificationRecord };

export type Custom_EngravingSectionRecordFragment_EngravingSectionRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_EngravingSectionRecordFragment_EngravingSectionRecord_optImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_EngravingSectionRecordFragment_EngravingSectionRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_EngravingSectionRecordFragment_EngravingSectionRecord_form_EngravingFormRecord = (
  { __typename: 'EngravingFormRecord' }
  & Custom_EngravingFormRecordFragment
);

export type Custom_EngravingSectionRecordFragment = { __typename: 'EngravingSectionRecord', id: string, optText?: string | null, optImage?: Custom_EngravingSectionRecordFragment_EngravingSectionRecord_optImage_ImageFileField | null, form: Custom_EngravingSectionRecordFragment_EngravingSectionRecord_form_EngravingFormRecord };

export type Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord_images_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord_images_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord_images_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord = { __typename: 'GalleryRecord', id: string, title: string, displayType: boolean, sections: Array<Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord_sections_ContentSectionRecord>, images: Array<Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord_images_ImageFileField> };

export type Custom_GallerySectionRecordFragment = { __typename: 'GallerySectionRecord', id: string, gallery: Custom_GallerySectionRecordFragment_GallerySectionRecord_gallery_GalleryRecord };

export type Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_IconLinkRecordFragment_IconLinkRecord_linkItem = Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_CustomLinkRecord | Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_PageLinkRecord;

export type Custom_IconLinkRecordFragment = { __typename: 'IconLinkRecord', _modelApiKey: string, id: string, iconName: string, linkItem: Custom_IconLinkRecordFragment_IconLinkRecord_linkItem };

export type Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ImageBlockRecordFragment = { __typename: 'ImageBlockRecord', _modelApiKey: string, id: string, image: Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField };

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem = Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_CustomLinkRecord | Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_PageLinkRecord;

export type Custom_ImageLinkRecordFragment = { __typename: 'ImageLinkRecord', _modelApiKey: string, id: string, image: Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField, linkItem: Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem };

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem = Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_CustomLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_PageLinkRecord;

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems = Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_CustomLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_PageLinkRecord;

export type Custom_MenuLinkRecordFragment = { __typename: 'MenuLinkRecord', _modelApiKey: string, id: string, linkItem: Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem, subLinkItems: Array<Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems> };

export type Custom_PageLinkRecordFragment_PageLinkRecord_link_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_PageLinkRecordFragment = { __typename: 'PageLinkRecord', _modelApiKey: string, id: string, text: string, highlight: boolean, link: Custom_PageLinkRecordFragment_PageLinkRecord_link_PageRecord };

export type Custom_PaymentInfoRecordFragment_PaymentInfoRecord_paymentProviderLogo_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_PaymentInfoRecordFragment_PaymentInfoRecord_paymentProviderLogo_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_PaymentInfoRecordFragment_PaymentInfoRecord_paymentProviderLogo_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_PaymentInfoRecordFragment = { __typename: 'PaymentInfoRecord', id: string, title: string, text: string, paymentProviderLogo: Array<Custom_PaymentInfoRecordFragment_PaymentInfoRecord_paymentProviderLogo_ImageFileField> };

export type Custom_ProductsSectionRecordFragment = { __typename: 'ProductsSectionRecord', id: string, optTitle?: string | null, products?: unknown | null };

export type Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem = Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_CustomLinkRecord | Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_PageLinkRecord;

export type Custom_SplitItemRecordFragment = { __typename: 'SplitItemRecord', _modelApiKey: string, id: string, optTitle?: string | null, text: string, imagePosition: boolean, image: Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField, optLinkItem?: Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem | null };

export type Custom_SplitSectionRecordFragment_SplitSectionRecord_splitItems_SplitItemRecord = (
  { __typename: 'SplitItemRecord' }
  & Custom_SplitItemRecordFragment
);

export type Custom_SplitSectionRecordFragment = { __typename: 'SplitSectionRecord', id: string, splitItems: Array<Custom_SplitSectionRecordFragment_SplitSectionRecord_splitItems_SplitItemRecord> };

export type Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_images_ImageLinkRecord = (
  { __typename: 'ImageLinkRecord' }
  & Custom_ImageLinkRecordFragment
);

export type Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_richText_SwiperInfoCtaSectionModelRichTextField = { __typename: 'SwiperInfoCtaSectionModelRichTextField', blocks: Array<string>, links: Array<string>, value: unknown };

export type Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_cta_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_cta_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_cta = Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_cta_CustomLinkRecord | Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_cta_PageLinkRecord;

export type Custom_SwiperInfoCtaSectionRecordFragment = { __typename: 'SwiperInfoCtaSectionRecord', id: string, images: Array<Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_images_ImageLinkRecord>, richText: Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_richText_SwiperInfoCtaSectionModelRichTextField, cta?: Custom_SwiperInfoCtaSectionRecordFragment_SwiperInfoCtaSectionRecord_cta | null };

export type Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_linkItem = Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_linkItem_CustomLinkRecord | Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_linkItem_PageLinkRecord;

export type Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord = { __typename: 'UspRecord', id: string, title: string, image: Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_image_ImageFileField, linkItem?: Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord_linkItem | null };

export type Custom_UspSectionRecordFragment = { __typename: 'UspSectionRecord', id: string, uspItems: Array<Custom_UspSectionRecordFragment_UspSectionRecord_uspItems_UspRecord> };

export type DatoImage_ResponsiveImageFragment = { __typename: 'ResponsiveImage', width: number, height: number, src: string, srcSet: string, webpSrcSet: string, alt?: string | null, title?: string | null, sizes: string, base64?: string | null, aspectRatio: number };

export type AccountPageQuery_accountPage_AccountPageRecord = { __typename: 'AccountPageRecord', loginTitle: string, loginIntroHtml: string, registrationTitle: string, registrationIntroHtml: string, registrationButtonText: string, resetRequestTitle: string, resetRequestIntroHtml: string, resetRequestButtonText: string, resetRequestConfirmationHtml: string };

export type AccountPageQuery_Query = { accountPage?: AccountPageQuery_accountPage_AccountPageRecord | null };


export type AccountPageQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type AccountPageQuery = AccountPageQuery_Query;

export type AddressFormQuery_addressForm_AddressFormRecord_countries_CountryRecord = { __typename: 'CountryRecord', id: string, name: string, isoCode: string };

export type AddressFormQuery_addressForm_AddressFormRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type AddressFormQuery_addressForm_AddressFormRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type AddressFormQuery_addressForm_AddressFormRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type AddressFormQuery_addressForm_AddressFormRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type AddressFormQuery_addressForm_AddressFormRecord = { __typename: 'AddressFormRecord', id: string, title: string, countries: Array<AddressFormQuery_addressForm_AddressFormRecord_countries_CountryRecord>, confirmation: AddressFormQuery_addressForm_AddressFormRecord_confirmation_NotificationRecord, exception: AddressFormQuery_addressForm_AddressFormRecord_exception_NotificationRecord, _seoMetaTags: Array<AddressFormQuery_addressForm_AddressFormRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<AddressFormQuery_addressForm_AddressFormRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type AddressFormQuery_Query = { addressForm?: AddressFormQuery_addressForm_AddressFormRecord | null };


export type AddressFormQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type AddressFormQuery = AddressFormQuery_Query;

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord_countries_CountryRecord = { __typename: 'CountryRecord', id: string, name: string, isoCode: string };

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord_duplicate_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type RegistrationFormQuery_registrationForm_RegistrationFormRecord = { __typename: 'RegistrationFormRecord', id: string, title: string, selectB2bType: string, selectB2cType: string, selectIntroHtml?: string | null, countries: Array<RegistrationFormQuery_registrationForm_RegistrationFormRecord_countries_CountryRecord>, confirmation: RegistrationFormQuery_registrationForm_RegistrationFormRecord_confirmation_NotificationRecord, exception: RegistrationFormQuery_registrationForm_RegistrationFormRecord_exception_NotificationRecord, duplicate: RegistrationFormQuery_registrationForm_RegistrationFormRecord_duplicate_NotificationRecord, _seoMetaTags: Array<RegistrationFormQuery_registrationForm_RegistrationFormRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<RegistrationFormQuery_registrationForm_RegistrationFormRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type RegistrationFormQuery_Query = { registrationForm?: RegistrationFormQuery_registrationForm_RegistrationFormRecord | null };


export type RegistrationFormQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type RegistrationFormQuery = RegistrationFormQuery_Query;

export type CheckoutQuery_checkout_CheckoutRecord_indexList_BulletListRecord = (
  { __typename: 'BulletListRecord' }
  & Custom_BulletListRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_informationList_BulletListRecord = (
  { __typename: 'BulletListRecord' }
  & Custom_BulletListRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_deliveryInfoLink_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_deliveryInfoLink_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_deliveryInfoLink = CheckoutQuery_checkout_CheckoutRecord_deliveryInfoLink_CustomLinkRecord | CheckoutQuery_checkout_CheckoutRecord_deliveryInfoLink_PageLinkRecord;

export type CheckoutQuery_checkout_CheckoutRecord_paymentInfo_PaymentInfoRecord = (
  { __typename: 'PaymentInfoRecord' }
  & Custom_PaymentInfoRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_giftInfoLink_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_giftInfoLink_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type CheckoutQuery_checkout_CheckoutRecord_giftInfoLink = CheckoutQuery_checkout_CheckoutRecord_giftInfoLink_CustomLinkRecord | CheckoutQuery_checkout_CheckoutRecord_giftInfoLink_PageLinkRecord;

export type CheckoutQuery_checkout_CheckoutRecord = { __typename: 'CheckoutRecord', id: string, acceptText: string, confirmationText: string, indexList?: CheckoutQuery_checkout_CheckoutRecord_indexList_BulletListRecord | null, informationList?: CheckoutQuery_checkout_CheckoutRecord_informationList_BulletListRecord | null, deliveryInfoLink?: CheckoutQuery_checkout_CheckoutRecord_deliveryInfoLink | null, paymentInfo: CheckoutQuery_checkout_CheckoutRecord_paymentInfo_PaymentInfoRecord, giftInfoLink?: CheckoutQuery_checkout_CheckoutRecord_giftInfoLink | null };

export type CheckoutQuery_Query = { checkout?: CheckoutQuery_checkout_CheckoutRecord | null };


export type CheckoutQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type CheckoutQuery = CheckoutQuery_Query;

export type DomainsDetailQuery_domain_DomainRecord_logo_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type DomainsDetailQuery_domain_DomainRecord_logo_ImageFileField = { __typename: 'ImageFileField', responsiveImage: DomainsDetailQuery_domain_DomainRecord_logo_ImageFileField_responsiveImage_ResponsiveImage };

export type DomainsDetailQuery_domain_DomainRecord_text_DomainModelTextField = { __typename: 'DomainModelTextField', blocks: Array<string>, links: Array<string>, value: unknown };

export type DomainsDetailQuery_domain_DomainRecord_bannerImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type DomainsDetailQuery_domain_DomainRecord_bannerImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: DomainsDetailQuery_domain_DomainRecord_bannerImage_ImageFileField_responsiveImage_ResponsiveImage };

export type DomainsDetailQuery_domain_DomainRecord_images_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type DomainsDetailQuery_domain_DomainRecord_images_ImageFileField = { __typename: 'ImageFileField', responsiveImage: DomainsDetailQuery_domain_DomainRecord_images_ImageFileField_responsiveImage_ResponsiveImage };

export type DomainsDetailQuery_domain_DomainRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type DomainsDetailQuery_domain_DomainRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type DomainsDetailQuery_domain_DomainRecord = { __typename: 'DomainRecord', id: string, name: string, slug: string, bannerTitle?: string | null, bannerText?: string | null, bannerLinkText?: string | null, logo: DomainsDetailQuery_domain_DomainRecord_logo_ImageFileField, text?: DomainsDetailQuery_domain_DomainRecord_text_DomainModelTextField | null, bannerImage?: DomainsDetailQuery_domain_DomainRecord_bannerImage_ImageFileField | null, images: Array<DomainsDetailQuery_domain_DomainRecord_images_ImageFileField>, _seoMetaTags: Array<DomainsDetailQuery_domain_DomainRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<DomainsDetailQuery_domain_DomainRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type DomainsDetailQuery_Query = { domain?: DomainsDetailQuery_domain_DomainRecord | null };


export type DomainsDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type DomainsDetailQuery = DomainsDetailQuery_Query;

export type Error404Query_error404_Error404Record_body_Error404ModelBodyField_blocks_ImageBlockRecord = (
  { __typename: 'ImageBlockRecord' }
  & Custom_ImageBlockRecordFragment
);

export type Error404Query_error404_Error404Record_body_Error404ModelBodyField = { __typename: 'Error404ModelBodyField', links: Array<string>, value: unknown, blocks: Array<Error404Query_error404_Error404Record_body_Error404ModelBodyField_blocks_ImageBlockRecord> };

export type Error404Query_error404_Error404Record__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type Error404Query_error404_Error404Record = { __typename: 'Error404Record', id: string, body: Error404Query_error404_Error404Record_body_Error404ModelBodyField, _seoMetaTags: Array<Error404Query_error404_Error404Record__seoMetaTags_Tag> };

export type Error404Query_Query = { error404?: Error404Query_error404_Error404Record | null };


export type Error404QueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type Error404Query = Error404Query_Query;

export type GalleriesDetailQuery_gallery_GalleryRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type GalleriesDetailQuery_gallery_GalleryRecord_images_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type GalleriesDetailQuery_gallery_GalleryRecord_images_ImageFileField = { __typename: 'ImageFileField', responsiveImage: GalleriesDetailQuery_gallery_GalleryRecord_images_ImageFileField_responsiveImage_ResponsiveImage };

export type GalleriesDetailQuery_gallery_GalleryRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type GalleriesDetailQuery_gallery_GalleryRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type GalleriesDetailQuery_gallery_GalleryRecord = { __typename: 'GalleryRecord', id: string, title: string, displayType: boolean, sections: Array<GalleriesDetailQuery_gallery_GalleryRecord_sections_ContentSectionRecord>, images: Array<GalleriesDetailQuery_gallery_GalleryRecord_images_ImageFileField>, _seoMetaTags: Array<GalleriesDetailQuery_gallery_GalleryRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<GalleriesDetailQuery_gallery_GalleryRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type GalleriesDetailQuery_Query = { gallery?: GalleriesDetailQuery_gallery_GalleryRecord | null };


export type GalleriesDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type GalleriesDetailQuery = GalleriesDetailQuery_Query;

export type GalleriesQuery_allGalleries_GalleryRecord_optTeaserImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type GalleriesQuery_allGalleries_GalleryRecord_optTeaserImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: GalleriesQuery_allGalleries_GalleryRecord_optTeaserImage_ImageFileField_responsiveImage_ResponsiveImage };

export type GalleriesQuery_allGalleries_GalleryRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type GalleriesQuery_allGalleries_GalleryRecord = { __typename: 'GalleryRecord', id: string, optTeaserTitle?: string | null, optTeaserLinkText?: string | null, slug: string, optTeaserImage?: GalleriesQuery_allGalleries_GalleryRecord_optTeaserImage_ImageFileField | null, _allSlugLocales?: Array<GalleriesQuery_allGalleries_GalleryRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type GalleriesQuery_Query = { allGalleries: Array<GalleriesQuery_allGalleries_GalleryRecord> };


export type GalleriesQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
  filterLocales?: InputMaybe<Array<SiteLocale>>;
}>;


export type GalleriesQuery = GalleriesQuery_Query;

export type LayoutQuery_layout_LayoutRecord_topBar_IconLinkRecord = (
  { __typename: 'IconLinkRecord' }
  & Custom_IconLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_topMenuLeft_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_topMenuLeft_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_topMenuLeft = LayoutQuery_layout_LayoutRecord_topMenuLeft_CustomLinkRecord | LayoutQuery_layout_LayoutRecord_topMenuLeft_PageLinkRecord;

export type LayoutQuery_layout_LayoutRecord_topMenuRight_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_topMenuRight_IconLinkRecord = (
  { __typename: 'IconLinkRecord' }
  & Custom_IconLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_topMenuRight_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_topMenuRight = LayoutQuery_layout_LayoutRecord_topMenuRight_CustomLinkRecord | LayoutQuery_layout_LayoutRecord_topMenuRight_IconLinkRecord | LayoutQuery_layout_LayoutRecord_topMenuRight_PageLinkRecord;

export type LayoutQuery_layout_LayoutRecord_mainMenu_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_mainMenu_IconLinkRecord = (
  { __typename: 'IconLinkRecord' }
  & Custom_IconLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_mainMenu_MenuLinkRecord = (
  { __typename: 'MenuLinkRecord' }
  & Custom_MenuLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_mainMenu_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_mainMenu = LayoutQuery_layout_LayoutRecord_mainMenu_CustomLinkRecord | LayoutQuery_layout_LayoutRecord_mainMenu_IconLinkRecord | LayoutQuery_layout_LayoutRecord_mainMenu_MenuLinkRecord | LayoutQuery_layout_LayoutRecord_mainMenu_PageLinkRecord;

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord = { __typename: 'NewsletterRecord', id: string, title: string, inputPlaceholder?: string | null, buttonText: string, confirmation: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_confirmation_NotificationRecord, exception: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_exception_NotificationRecord };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord = { __typename: 'NewsletterSubscriptionRecord', id: string, newsletter: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord };

export type LayoutQuery_layout_LayoutRecord_paymentInfo_PaymentInfoRecord = (
  { __typename: 'PaymentInfoRecord' }
  & Custom_PaymentInfoRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_socialMedia_IconLinkRecord = (
  { __typename: 'IconLinkRecord' }
  & Custom_IconLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord_linkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord_linkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord_linkItems = LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord_linkItems_CustomLinkRecord | LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord_linkItems_PageLinkRecord;

export type LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord = { __typename: 'LinkColumnRecord', id: string, title: string, linkItems: Array<LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord_linkItems> };

export type LayoutQuery_layout_LayoutRecord = { __typename: 'LayoutRecord', id: string, topBar: Array<LayoutQuery_layout_LayoutRecord_topBar_IconLinkRecord>, topMenuLeft: Array<LayoutQuery_layout_LayoutRecord_topMenuLeft>, topMenuRight: Array<LayoutQuery_layout_LayoutRecord_topMenuRight>, mainMenu: Array<LayoutQuery_layout_LayoutRecord_mainMenu>, newsletterSubscription?: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord | null, paymentInfo?: LayoutQuery_layout_LayoutRecord_paymentInfo_PaymentInfoRecord | null, socialMedia: Array<LayoutQuery_layout_LayoutRecord_socialMedia_IconLinkRecord>, linkColumns: Array<LayoutQuery_layout_LayoutRecord_linkColumns_LinkColumnRecord> };

export type LayoutQuery_Query = { layout?: LayoutQuery_layout_LayoutRecord | null };


export type LayoutQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type LayoutQuery = LayoutQuery_Query;

export type OrganizationQuery_organization_OrganizationRecord_openingHours_HourRecord = { __typename: 'HourRecord', id: string, dayOfWeek: string, text: string };

export type OrganizationQuery_organization_OrganizationRecord_closingDays_DateRecord = { __typename: 'DateRecord', id: string, label: string, value: string };

export type OrganizationQuery_organization_OrganizationRecord = { __typename: 'OrganizationRecord', id: string, name: string, street: string, number: string, postalCode: string, city: string, countryCode: string, phone: string, email: string, openingHours: Array<OrganizationQuery_organization_OrganizationRecord_openingHours_HourRecord>, closingDays: Array<OrganizationQuery_organization_OrganizationRecord_closingDays_DateRecord> };

export type OrganizationQuery_Query = { organization?: OrganizationQuery_organization_OrganizationRecord | null };


export type OrganizationQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type OrganizationQuery = OrganizationQuery_Query;

export type PageQuery_page_PageRecord_sections_AllDomainsSectionRecord = (
  { __typename: 'AllDomainsSectionRecord' }
  & Custom_AllDomainsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_AllGalleriesSectionRecord = (
  { __typename: 'AllGalleriesSectionRecord' }
  & Custom_AllGalleriesSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ArticlesSectionRecord = (
  { __typename: 'ArticlesSectionRecord' }
  & Custom_ArticlesSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_BannerSectionRecord = (
  { __typename: 'BannerSectionRecord' }
  & Custom_BannerSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_CategoriesSectionRecord = { __typename: 'CategoriesSectionRecord' };

export type PageQuery_page_PageRecord_sections_ContactInfoCtaSectionRecord = (
  { __typename: 'ContactInfoCtaSectionRecord' }
  & Custom_ContactInfoCtaSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_DiscoverySectionRecord = (
  { __typename: 'DiscoverySectionRecord' }
  & Custom_DiscoverySectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_EngravingSectionRecord = (
  { __typename: 'EngravingSectionRecord' }
  & Custom_EngravingSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_GallerySectionRecord = (
  { __typename: 'GallerySectionRecord' }
  & Custom_GallerySectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ProductsSectionRecord = (
  { __typename: 'ProductsSectionRecord' }
  & Custom_ProductsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_SplitSectionRecord = (
  { __typename: 'SplitSectionRecord' }
  & Custom_SplitSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_SwiperInfoCtaSectionRecord = (
  { __typename: 'SwiperInfoCtaSectionRecord' }
  & Custom_SwiperInfoCtaSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_UspSectionRecord = (
  { __typename: 'UspSectionRecord' }
  & Custom_UspSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections = PageQuery_page_PageRecord_sections_AllDomainsSectionRecord | PageQuery_page_PageRecord_sections_AllGalleriesSectionRecord | PageQuery_page_PageRecord_sections_ArticlesSectionRecord | PageQuery_page_PageRecord_sections_BannerSectionRecord | PageQuery_page_PageRecord_sections_CategoriesSectionRecord | PageQuery_page_PageRecord_sections_ContactInfoCtaSectionRecord | PageQuery_page_PageRecord_sections_ContentSectionRecord | PageQuery_page_PageRecord_sections_DiscoverySectionRecord | PageQuery_page_PageRecord_sections_EngravingSectionRecord | PageQuery_page_PageRecord_sections_GallerySectionRecord | PageQuery_page_PageRecord_sections_ProductsSectionRecord | PageQuery_page_PageRecord_sections_SplitSectionRecord | PageQuery_page_PageRecord_sections_SwiperInfoCtaSectionRecord | PageQuery_page_PageRecord_sections_UspSectionRecord;

export type PageQuery_page_PageRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type PageQuery_page_PageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type PageQuery_page_PageRecord = { __typename: 'PageRecord', id: string, title: string, sections: Array<PageQuery_page_PageRecord_sections>, _seoMetaTags: Array<PageQuery_page_PageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<PageQuery_page_PageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type PageQuery_Query = { page?: PageQuery_page_PageRecord | null };


export type PageQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type PageQuery = PageQuery_Query;

export type CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord_link_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord_link_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord_link = CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord_link_CustomLinkRecord | CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord_link_PageLinkRecord;

export type CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord = { __typename: 'CategoryAdviceRecord', id: string, category: unknown, link: CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord_link };

export type CategoryAdvicesQuery_Query = { allCategoryAdvices: Array<CategoryAdvicesQuery_allCategoryAdvices_CategoryAdviceRecord> };


export type CategoryAdvicesQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type CategoryAdvicesQuery = CategoryAdvicesQuery_Query;

export type PopularCategoriesQuery_allPopularCategories_PopularCategoryRecord_links_CustomLinkRecord = { __typename: 'CustomLinkRecord', id: string, text: string, optLinkUrl?: string | null };

export type PopularCategoriesQuery_allPopularCategories_PopularCategoryRecord = { __typename: 'PopularCategoryRecord', id: string, category: unknown, links: Array<PopularCategoriesQuery_allPopularCategories_PopularCategoryRecord_links_CustomLinkRecord> };

export type PopularCategoriesQuery_Query = { allPopularCategories: Array<PopularCategoriesQuery_allPopularCategories_PopularCategoryRecord> };


export type PopularCategoriesQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type PopularCategoriesQuery = PopularCategoriesQuery_Query;

export type ProductAvailabilityFormQuery_productAvailabilityForm_ProductAvailabilityFormRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type ProductAvailabilityFormQuery_productAvailabilityForm_ProductAvailabilityFormRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type ProductAvailabilityFormQuery_productAvailabilityForm_ProductAvailabilityFormRecord = { __typename: 'ProductAvailabilityFormRecord', id: string, title: string, acceptText: string, confirmation: ProductAvailabilityFormQuery_productAvailabilityForm_ProductAvailabilityFormRecord_confirmation_NotificationRecord, exception: ProductAvailabilityFormQuery_productAvailabilityForm_ProductAvailabilityFormRecord_exception_NotificationRecord };

export type ProductAvailabilityFormQuery_Query = { productAvailabilityForm?: ProductAvailabilityFormQuery_productAvailabilityForm_ProductAvailabilityFormRecord | null };


export type ProductAvailabilityFormQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type ProductAvailabilityFormQuery = ProductAvailabilityFormQuery_Query;

export type SitemapDomainsQuery_records_DomainRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapDomainsQuery_records_DomainRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapDomainsQuery_records_DomainRecord = { __typename: 'DomainRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapDomainsQuery_records_DomainRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapDomainsQuery_records_DomainRecord_seoMeta_SeoField | null };

export type SitemapDomainsQuery_meta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type SitemapDomainsQuery_Query = { records: Array<SitemapDomainsQuery_records_DomainRecord>, meta: SitemapDomainsQuery_meta_CollectionMetadata };


export type SitemapDomainsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['IntType']['input']>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
}>;


export type SitemapDomainsQuery = SitemapDomainsQuery_Query;

export type SitemapGalleriesQuery_records_GalleryRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapGalleriesQuery_records_GalleryRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapGalleriesQuery_records_GalleryRecord = { __typename: 'GalleryRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapGalleriesQuery_records_GalleryRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapGalleriesQuery_records_GalleryRecord_seoMeta_SeoField | null };

export type SitemapGalleriesQuery_meta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type SitemapGalleriesQuery_Query = { records: Array<SitemapGalleriesQuery_records_GalleryRecord>, meta: SitemapGalleriesQuery_meta_CollectionMetadata };


export type SitemapGalleriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['IntType']['input']>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
}>;


export type SitemapGalleriesQuery = SitemapGalleriesQuery_Query;

export type SitemapPagesQuery_records_PageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapPagesQuery_records_PageRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapPagesQuery_records_PageRecord = { __typename: 'PageRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapPagesQuery_records_PageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapPagesQuery_records_PageRecord_seoMeta_SeoField | null };

export type SitemapPagesQuery_meta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type SitemapPagesQuery_Query = { records: Array<SitemapPagesQuery_records_PageRecord>, meta: SitemapPagesQuery_meta_CollectionMetadata };


export type SitemapPagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['IntType']['input']>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
}>;


export type SitemapPagesQuery = SitemapPagesQuery_Query;

export type RedirectMappingsQuery_redirectMap_RedirectMapRecord = { __typename: 'RedirectMapRecord', redirects: unknown };

export type RedirectMappingsQuery_Query = { redirectMap?: RedirectMappingsQuery_redirectMap_RedirectMapRecord | null };


export type RedirectMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type RedirectMappingsQuery = RedirectMappingsQuery_Query;

export const DatoImage_ResponsiveImageFragmentDoc = gql`
    fragment DatoImage_ResponsiveImage on ResponsiveImage {
  width
  height
  src
  srcSet
  webpSrcSet
  alt
  title
  sizes
  base64
  aspectRatio
}
    `;
export const Custom_AllDomainsSectionRecordFragmentDoc = gql`
    fragment Custom_AllDomainsSectionRecord on AllDomainsSectionRecord {
  id
  domains {
    id
    logo {
      responsiveImage(imgixParams: {w: "300", auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    name
    slug
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_AllGalleriesSectionRecordFragmentDoc = gql`
    fragment Custom_AllGalleriesSectionRecord on AllGalleriesSectionRecord {
  id
}
    `;
export const Custom_CustomLinkRecordFragmentDoc = gql`
    fragment Custom_CustomLinkRecord on CustomLinkRecord {
  _modelApiKey
  id
  text
  optLinkUrl
  highlight
}
    `;
export const Custom_PageLinkRecordFragmentDoc = gql`
    fragment Custom_PageLinkRecord on PageLinkRecord {
  _modelApiKey
  id
  text
  link {
    slug
  }
  highlight
}
    `;
export const Custom_ArticleTeaserRecordFragmentDoc = gql`
    fragment Custom_ArticleTeaserRecord on ArticleTeaserRecord {
  id
  image {
    responsiveImage(
      imgixParams: {fit: crop, w: "500", h: "500", auto: [compress, format]}
    ) {
      ...DatoImage_ResponsiveImage
    }
  }
  title
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_ArticlesSectionRecordFragmentDoc = gql`
    fragment Custom_ArticlesSectionRecord on ArticlesSectionRecord {
  id
  teasers {
    ...Custom_ArticleTeaserRecord
  }
}
    ${Custom_ArticleTeaserRecordFragmentDoc}`;
export const Custom_BannerSectionRecordFragmentDoc = gql`
    fragment Custom_BannerSectionRecord on BannerSectionRecord {
  id
  image {
    responsiveImage(imgixParams: {w: "1500", auto: [compress]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  optTitle
  optText
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_BulletListRecordFragmentDoc = gql`
    fragment Custom_BulletListRecord on BulletListRecord {
  id
  optTitle
  list
}
    `;
export const Custom_ContactFormRecordFragmentDoc = gql`
    fragment Custom_ContactFormRecord on ContactFormRecord {
  id
  countries {
    id
    name
    isoCode
  }
  acceptText
  confirmation {
    optTitle
    text
  }
  exception {
    optTitle
    text
  }
}
    `;
export const Custom_ContactInfoCtaSectionRecordFragmentDoc = gql`
    fragment Custom_ContactInfoCtaSectionRecord on ContactInfoCtaSectionRecord {
  id
  richText {
    blocks
    links
    value
  }
  googleMapsEmbed
  cta {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
  form {
    ...Custom_ContactFormRecord
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${Custom_ContactFormRecordFragmentDoc}`;
export const Custom_DiscoverySectionRecordFragmentDoc = gql`
    fragment Custom_DiscoverySectionRecord on DiscoverySectionRecord {
  id
  title
  text
  linkColumns {
    id
    title
    linkItems {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_EngravingFormRecordFragmentDoc = gql`
    fragment Custom_EngravingFormRecord on EngravingFormRecord {
  id
  title
  acceptText
  confirmation {
    optTitle
    text
  }
  exception {
    optTitle
    text
  }
}
    `;
export const Custom_EngravingSectionRecordFragmentDoc = gql`
    fragment Custom_EngravingSectionRecord on EngravingSectionRecord {
  id
  optImage {
    responsiveImage(imgixParams: {w: "700", auto: [compress]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  optText
  form {
    ...Custom_EngravingFormRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_EngravingFormRecordFragmentDoc}`;
export const Custom_BannerItemRecordFragmentDoc = gql`
    fragment Custom_BannerItemRecord on BannerItemRecord {
  _modelApiKey
  id
  image {
    responsiveImage(imgixParams: {w: "1500", auto: [compress]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_ImageBlockRecordFragmentDoc = gql`
    fragment Custom_ImageBlockRecord on ImageBlockRecord {
  _modelApiKey
  id
  image {
    responsiveImage(imgixParams: {w: "1500", auto: [compress]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_SplitItemRecordFragmentDoc = gql`
    fragment Custom_SplitItemRecord on SplitItemRecord {
  _modelApiKey
  id
  optTitle
  text
  image {
    responsiveImage(
      imgixParams: {fit: crop, w: "700", h: "700", auto: [compress, format]}
    ) {
      ...DatoImage_ResponsiveImage
    }
  }
  imagePosition
  optLinkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_ContentSectionRecordFragmentDoc = gql`
    fragment Custom_ContentSectionRecord on ContentSectionRecord {
  id
  body {
    blocks {
      ...Custom_BannerItemRecord
      ...Custom_ImageBlockRecord
      ...Custom_SplitItemRecord
    }
    links
    value
  }
}
    ${Custom_BannerItemRecordFragmentDoc}
${Custom_ImageBlockRecordFragmentDoc}
${Custom_SplitItemRecordFragmentDoc}`;
export const Custom_GallerySectionRecordFragmentDoc = gql`
    fragment Custom_GallerySectionRecord on GallerySectionRecord {
  id
  gallery {
    id
    title
    sections {
      __typename
      ...Custom_ContentSectionRecord
    }
    images {
      responsiveImage {
        ...DatoImage_ResponsiveImage
      }
    }
    displayType
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}
${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_IconLinkRecordFragmentDoc = gql`
    fragment Custom_IconLinkRecord on IconLinkRecord {
  _modelApiKey
  id
  iconName
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_MenuLinkRecordFragmentDoc = gql`
    fragment Custom_MenuLinkRecord on MenuLinkRecord {
  _modelApiKey
  id
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
  subLinkItems {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_PaymentInfoRecordFragmentDoc = gql`
    fragment Custom_PaymentInfoRecord on PaymentInfoRecord {
  id
  title
  text
  paymentProviderLogo {
    responsiveImage(imgixParams: {w: "45", auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_ProductsSectionRecordFragmentDoc = gql`
    fragment Custom_ProductsSectionRecord on ProductsSectionRecord {
  id
  optTitle
  products
}
    `;
export const Custom_SplitSectionRecordFragmentDoc = gql`
    fragment Custom_SplitSectionRecord on SplitSectionRecord {
  id
  splitItems {
    ...Custom_SplitItemRecord
  }
}
    ${Custom_SplitItemRecordFragmentDoc}`;
export const Custom_ImageLinkRecordFragmentDoc = gql`
    fragment Custom_ImageLinkRecord on ImageLinkRecord {
  _modelApiKey
  id
  image {
    responsiveImage(imgixParams: {w: 1000, fit: max, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_SwiperInfoCtaSectionRecordFragmentDoc = gql`
    fragment Custom_SwiperInfoCtaSectionRecord on SwiperInfoCtaSectionRecord {
  id
  images {
    ...Custom_ImageLinkRecord
  }
  richText {
    blocks
    links
    value
  }
  cta {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${Custom_ImageLinkRecordFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_UspSectionRecordFragmentDoc = gql`
    fragment Custom_UspSectionRecord on UspSectionRecord {
  id
  uspItems {
    id
    image {
      responsiveImage(imgixParams: {w: "500", auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    title
    linkItem {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const AccountPageDocument = gql`
    query AccountPage($locale: SiteLocale) {
  accountPage(locale: $locale) {
    loginTitle
    loginIntroHtml
    registrationTitle
    registrationIntroHtml
    registrationButtonText
    resetRequestTitle
    resetRequestIntroHtml
    resetRequestButtonText
    resetRequestConfirmationHtml
  }
}
    `;
export const AddressFormDocument = gql`
    query AddressForm($locale: SiteLocale) {
  addressForm(locale: $locale) {
    id
    title
    countries {
      id
      name
      isoCode
    }
    confirmation {
      optTitle
      text
    }
    exception {
      optTitle
      text
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    `;
export const RegistrationFormDocument = gql`
    query RegistrationForm($locale: SiteLocale) {
  registrationForm(locale: $locale) {
    id
    title
    selectB2bType
    selectB2cType
    selectIntroHtml
    countries {
      id
      name
      isoCode
    }
    confirmation {
      optTitle
      text
    }
    exception {
      optTitle
      text
    }
    duplicate {
      optTitle
      text
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    `;
export const CheckoutDocument = gql`
    query Checkout($locale: SiteLocale) {
  checkout(locale: $locale) {
    id
    indexList {
      ...Custom_BulletListRecord
    }
    informationList {
      ...Custom_BulletListRecord
    }
    deliveryInfoLink {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
    paymentInfo {
      ...Custom_PaymentInfoRecord
    }
    giftInfoLink {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
    acceptText
    confirmationText
  }
}
    ${Custom_BulletListRecordFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${Custom_PaymentInfoRecordFragmentDoc}`;
export const DomainsDetailDocument = gql`
    query DomainsDetail($slug: String, $locale: SiteLocale) {
  domain(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    logo {
      responsiveImage(imgixParams: {w: "300", auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    name
    slug
    text {
      blocks
      links
      value
    }
    bannerImage {
      responsiveImage(imgixParams: {w: "1500", auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    bannerTitle
    bannerText
    bannerLinkText
    images {
      responsiveImage(imgixParams: {auto: [compress]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Error404Document = gql`
    query Error404($locale: SiteLocale) {
  error404(locale: $locale) {
    id
    body {
      blocks {
        ...Custom_ImageBlockRecord
      }
      links
      value
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
  }
}
    ${Custom_ImageBlockRecordFragmentDoc}`;
export const GalleriesDetailDocument = gql`
    query GalleriesDetail($slug: String, $locale: SiteLocale) {
  gallery(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    title
    sections {
      __typename
      ...Custom_ContentSectionRecord
    }
    images {
      responsiveImage(imgixParams: {auto: [compress]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    displayType
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}
${DatoImage_ResponsiveImageFragmentDoc}`;
export const GalleriesDocument = gql`
    query Galleries($locale: SiteLocale, $filterLocales: [SiteLocale!]) {
  allGalleries(
    locale: $locale
    orderBy: date_DESC
    filter: {_locales: {allIn: $filterLocales}, addToGalleryPage: {eq: true}}
  ) {
    id
    optTeaserTitle
    optTeaserLinkText
    optTeaserImage {
      responsiveImage(
        imgixParams: {fit: crop, w: "500", h: "500", auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
    slug
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const LayoutDocument = gql`
    query Layout($locale: SiteLocale) {
  layout(locale: $locale) {
    id
    topBar {
      ...Custom_IconLinkRecord
    }
    topMenuLeft {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
    topMenuRight {
      ...Custom_CustomLinkRecord
      ...Custom_IconLinkRecord
      ...Custom_PageLinkRecord
    }
    mainMenu {
      ...Custom_CustomLinkRecord
      ...Custom_IconLinkRecord
      ...Custom_MenuLinkRecord
      ...Custom_PageLinkRecord
    }
    newsletterSubscription {
      id
      newsletter {
        id
        title
        inputPlaceholder
        buttonText
        confirmation {
          optTitle
          text
        }
        exception {
          optTitle
          text
        }
      }
    }
    paymentInfo {
      ...Custom_PaymentInfoRecord
    }
    socialMedia {
      ...Custom_IconLinkRecord
    }
    linkColumns {
      id
      title
      linkItems {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
    }
  }
}
    ${Custom_IconLinkRecordFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${Custom_MenuLinkRecordFragmentDoc}
${Custom_PaymentInfoRecordFragmentDoc}`;
export const OrganizationDocument = gql`
    query Organization($locale: SiteLocale) {
  organization(locale: $locale) {
    id
    name
    street
    number
    postalCode
    city
    countryCode
    phone
    email
    openingHours {
      id
      dayOfWeek
      text
    }
    closingDays {
      id
      label
      value
    }
  }
}
    `;
export const PageDocument = gql`
    query Page($slug: String, $locale: SiteLocale) {
  page(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    title
    sections {
      __typename
      ...Custom_AllDomainsSectionRecord
      ...Custom_AllGalleriesSectionRecord
      ...Custom_ArticlesSectionRecord
      ...Custom_BannerSectionRecord
      ...Custom_ContactInfoCtaSectionRecord
      ...Custom_ContentSectionRecord
      ...Custom_DiscoverySectionRecord
      ...Custom_EngravingSectionRecord
      ...Custom_GallerySectionRecord
      ...Custom_ProductsSectionRecord
      ...Custom_SplitSectionRecord
      ...Custom_SwiperInfoCtaSectionRecord
      ...Custom_UspSectionRecord
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_AllDomainsSectionRecordFragmentDoc}
${Custom_AllGalleriesSectionRecordFragmentDoc}
${Custom_ArticlesSectionRecordFragmentDoc}
${Custom_BannerSectionRecordFragmentDoc}
${Custom_ContactInfoCtaSectionRecordFragmentDoc}
${Custom_ContentSectionRecordFragmentDoc}
${Custom_DiscoverySectionRecordFragmentDoc}
${Custom_EngravingSectionRecordFragmentDoc}
${Custom_GallerySectionRecordFragmentDoc}
${Custom_ProductsSectionRecordFragmentDoc}
${Custom_SplitSectionRecordFragmentDoc}
${Custom_SwiperInfoCtaSectionRecordFragmentDoc}
${Custom_UspSectionRecordFragmentDoc}`;
export const CategoryAdvicesDocument = gql`
    query CategoryAdvices($locale: SiteLocale) {
  allCategoryAdvices(locale: $locale) {
    id
    category
    link {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const PopularCategoriesDocument = gql`
    query PopularCategories($locale: SiteLocale) {
  allPopularCategories {
    id
    category
    links(locale: $locale) {
      id
      text
      optLinkUrl
    }
  }
}
    `;
export const ProductAvailabilityFormDocument = gql`
    query ProductAvailabilityForm($locale: SiteLocale) {
  productAvailabilityForm(locale: $locale) {
    id
    title
    acceptText
    confirmation {
      optTitle
      text
    }
    exception {
      optTitle
      text
    }
  }
}
    `;
export const SitemapDomainsDocument = gql`
    query SitemapDomains($first: IntType, $skip: IntType) {
  records: allDomains(first: $first, skip: $skip) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  meta: _allDomainsMeta {
    count
  }
}
    `;
export const SitemapGalleriesDocument = gql`
    query SitemapGalleries($first: IntType, $skip: IntType) {
  records: allGalleries(first: $first, skip: $skip) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  meta: _allGalleriesMeta {
    count
  }
}
    `;
export const SitemapPagesDocument = gql`
    query SitemapPages($first: IntType, $skip: IntType) {
  records: allPages(first: $first, skip: $skip) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  meta: _allPagesMeta {
    count
  }
}
    `;
export const RedirectMappingsDocument = gql`
    query RedirectMappings {
  redirectMap {
    redirects
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();
const AccountPageDocumentString = print(AccountPageDocument);
const AddressFormDocumentString = print(AddressFormDocument);
const RegistrationFormDocumentString = print(RegistrationFormDocument);
const CheckoutDocumentString = print(CheckoutDocument);
const DomainsDetailDocumentString = print(DomainsDetailDocument);
const Error404DocumentString = print(Error404Document);
const GalleriesDetailDocumentString = print(GalleriesDetailDocument);
const GalleriesDocumentString = print(GalleriesDocument);
const LayoutDocumentString = print(LayoutDocument);
const OrganizationDocumentString = print(OrganizationDocument);
const PageDocumentString = print(PageDocument);
const CategoryAdvicesDocumentString = print(CategoryAdvicesDocument);
const PopularCategoriesDocumentString = print(PopularCategoriesDocument);
const ProductAvailabilityFormDocumentString = print(ProductAvailabilityFormDocument);
const SitemapDomainsDocumentString = print(SitemapDomainsDocument);
const SitemapGalleriesDocumentString = print(SitemapGalleriesDocument);
const SitemapPagesDocumentString = print(SitemapPagesDocument);
const RedirectMappingsDocumentString = print(RedirectMappingsDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AccountPage(variables?: AccountPageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: AccountPageQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountPageQuery>(AccountPageDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountPage', 'query', variables);
    },
    AddressForm(variables?: AddressFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: AddressFormQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AddressFormQuery>(AddressFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddressForm', 'query', variables);
    },
    RegistrationForm(variables?: RegistrationFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: RegistrationFormQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<RegistrationFormQuery>(RegistrationFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegistrationForm', 'query', variables);
    },
    Checkout(variables?: CheckoutQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: CheckoutQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CheckoutQuery>(CheckoutDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Checkout', 'query', variables);
    },
    DomainsDetail(variables?: DomainsDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: DomainsDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DomainsDetailQuery>(DomainsDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DomainsDetail', 'query', variables);
    },
    Error404(variables?: Error404QueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: Error404Query; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<Error404Query>(Error404DocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Error404', 'query', variables);
    },
    GalleriesDetail(variables?: GalleriesDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: GalleriesDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GalleriesDetailQuery>(GalleriesDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GalleriesDetail', 'query', variables);
    },
    Galleries(variables?: GalleriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: GalleriesQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GalleriesQuery>(GalleriesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Galleries', 'query', variables);
    },
    Layout(variables?: LayoutQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: LayoutQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LayoutQuery>(LayoutDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Layout', 'query', variables);
    },
    Organization(variables?: OrganizationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: OrganizationQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<OrganizationQuery>(OrganizationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Organization', 'query', variables);
    },
    Page(variables?: PageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: PageQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PageQuery>(PageDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Page', 'query', variables);
    },
    CategoryAdvices(variables?: CategoryAdvicesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: CategoryAdvicesQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CategoryAdvicesQuery>(CategoryAdvicesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CategoryAdvices', 'query', variables);
    },
    PopularCategories(variables?: PopularCategoriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: PopularCategoriesQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PopularCategoriesQuery>(PopularCategoriesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PopularCategories', 'query', variables);
    },
    ProductAvailabilityForm(variables?: ProductAvailabilityFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: ProductAvailabilityFormQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ProductAvailabilityFormQuery>(ProductAvailabilityFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProductAvailabilityForm', 'query', variables);
    },
    SitemapDomains(variables?: SitemapDomainsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SitemapDomainsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SitemapDomainsQuery>(SitemapDomainsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SitemapDomains', 'query', variables);
    },
    SitemapGalleries(variables?: SitemapGalleriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SitemapGalleriesQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SitemapGalleriesQuery>(SitemapGalleriesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SitemapGalleries', 'query', variables);
    },
    SitemapPages(variables?: SitemapPagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SitemapPagesQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SitemapPagesQuery>(SitemapPagesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SitemapPages', 'query', variables);
    },
    RedirectMappings(variables?: RedirectMappingsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: RedirectMappingsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<RedirectMappingsQuery>(RedirectMappingsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RedirectMappings', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;