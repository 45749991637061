// https://docs.sentry.io/platforms/javascript/
// SENTRY BROWSER TRACING:      ✅ ENABLED
// SENTRY BROWSER SESS REPLAY:  ✅ ENABLED
// SENTRY BROWSER PROFILING:    🚫 NOT YET
// SENTRY BROWSER METRICS:      🚫 NOT YET
import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
  const { replayIntegration } = await import("@sentry/vue");
  Sentry.addIntegration(replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }));
}

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  setup: async function (nuxtApp) {
    const { public: publicConfig } = useRuntimeConfig();

    if (!publicConfig.SENTRY_DSN_PUBLIC) {
      return;
    }

    const vueApp = nuxtApp.vueApp;
    const router = useRouter();

    Sentry.init({
      app: vueApp,
      dsn: publicConfig.SENTRY_DSN_PUBLIC as string ?? null,
      environment: publicConfig.SENTRY_ENVIRONMENT as string ?? "not set",
      integrations: [
        Sentry.browserTracingIntegration({ router: router }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // It is recommend to adjust this value in production
      tracesSampleRate: publicConfig.SENTRY_TRACES_SAMPLE_RATE as number,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: publicConfig.SENTRY_REPLAY_SAMPLE_RATE as number,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: publicConfig.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,

      logErrors: false, // disable this because it conflicts with NuxtErrorBoundary
      ignoreErrors: ["Page Not Found"],
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  },
});
