import { SiteLocale } from "~/graphql/datocms/generated";

const datoEnumToCulture: Map<SiteLocale, string> = new Map([
  [SiteLocale.NlBe, "nl-be"],
]);

const cultureToDatoEnum: Map<string, SiteLocale> = new Map([
  ["nl-be", SiteLocale.NlBe],
]);

export function getCultureFromDatoLocale(datoLocale: SiteLocale): string {
  return datoEnumToCulture.get(datoLocale) as string;
}

export function getDatoLocaleFromCulture(culture: string): SiteLocale {
  return cultureToDatoEnum.get(culture.toLocaleLowerCase()) as SiteLocale;
}

const propellerStringToCulture: Map<string, string> = new Map([
  ["nl", "nl-be"],
]);

const cultureToPropellerString: Map<string, string> = new Map([
  ["nl-be", "nl"],
]);

export function getCultureFromPropellerLocale(propellerLocale: string): string {
  return propellerStringToCulture.get(propellerLocale.toLowerCase()) as string;
}

export function getPropellerLocaleFromCulture(culture: string): string {
  return cultureToPropellerString.get(culture.toLocaleLowerCase()) as SiteLocale;
}
