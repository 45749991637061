<script lang="ts" setup>
import { type SeoMetaTagType, toHead } from "vue-datocms";

defineProps({
  error: Object,
});

const { data } = await useFetch("/api/cms/error/404");

const showCustomPage = computed(() => {
  return data.value;
});

if (data.value) {
  useHead(() => {
    if (!data.value) {
      return {};
    }

    return toHead(data.value._seoMetaTags as Array<SeoMetaTagType>);
  });
}
</script>

<template>
  <NuxtLayout>
    <StructuredContent v-if="showCustomPage" :data="data?.body" />
    <ErrorAll v-if="!showCustomPage" :error="error" />
  </NuxtLayout>
</template>

<style scoped>

</style>
