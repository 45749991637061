import type {
  CustomLinkRecord,
  IconLinkRecord,
  ImageLinkRecord,
  MenuLinkRecord,
  PageLinkRecord,
} from "~/graphql/datocms/generated";
import type { LinkItem } from "~/models/links";

export function getLinkForType(link: any, localePath: (path: string) => string, target?: string): LinkItem {
  if (!link) {
    return { id: "0", label: `Unknown link`, highlight: false };
  }

  if (link?._modelApiKey === "custom_link") {
    const customLink = link as CustomLinkRecord;

    return {
      id: customLink.id,
      label: customLink.text,
      href: customLink.optLinkUrl,
      target: target ?? getTarget(customLink.optLinkUrl || "/"),
      highlight: customLink.highlight,
    };
  }

  if (link?._modelApiKey === "icon_link") {
    const iconLink = link as IconLinkRecord;

    const iconLinkItem = getLinkForType(iconLink.linkItem, localePath);

    if (iconLinkItem) {
      iconLinkItem.iconName = iconLink.iconName;
    }

    return iconLinkItem;
  }

  if (link?._modelApiKey === "image_link") {
    const imageLink = link as ImageLinkRecord;

    const imageLinkItem = getLinkForType(imageLink.linkItem, localePath);

    if (imageLinkItem) {
      imageLinkItem.image = imageLink.image;
    }

    return imageLinkItem;
  }

  if (link?._modelApiKey === "menu_link") {
    const menuLink = link as MenuLinkRecord;

    const menuLinkItem = getLinkForType(menuLink.linkItem, localePath);

    if (menuLinkItem) {
      // enclose the link items within an array, so it's usable for NuxtUI's UDropdown component
      menuLinkItem.subLinks = menuLink.subLinkItems.map(l => [getLinkForType(l, localePath)]);
    }

    return menuLinkItem;
  }

  if (link?._modelApiKey === "page_link") {
    const pageLink = link as PageLinkRecord;

    return {
      id: pageLink.id,
      label: pageLink.text,
      href: getUrl(pageLink.link!.slug, localePath),
      target: target ?? getTarget(getUrl(pageLink.link!.slug, localePath)),
      highlight: pageLink.highlight,
    };
  }

  return { id: "0", label: `Unknown link type ${link._modelApiKey}`, highlight: false };
}

export function getTarget(path: string) {
  try {
    if (path.startsWith("http")) {
      const pathUrl = new URL(path);
      const requestUrl = useRequestURL();

      if (pathUrl.host !== requestUrl.host) {
        return "_blank";
      }
    }

    return "_self";
  }
  catch {
    return "_blank";
  }
}

export function getUrl(path: string, localePath: (path: string) => string) {
  if (path.indexOf(":") > 0) {
    return path;
  }

  return localePath(`/${path}`);
}

export function createClusterUrl(clusterId: number, slug: string | null | undefined) {
  let validSlug = slug?.trim() || "name";

  // remove all hyphens at the end of the slug
  validSlug = validSlug.replace(/-+$/, "");

  return `/product/${validSlug}--${clusterId}`;
}
